import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

import { serializeError } from 'src/utils/redux';
import { getAuditProjectById } from 'src/api/dataTransformation/auditProjects';

import { AuditProjectResponse, ServerFormErrors } from 'src/types';

type ConfigurationState = {
  configuration: AuditProjectResponse | null;
  serverErrors: ServerFormErrors;
};

export const fetchConfigurationByIdAction = createAsyncThunk(
  'configuration/fetchConfigurationByIdAction',
  async (configurationId: string) => {
    const res = await getAuditProjectById(configurationId);
    return res.data;
  },
  {
    serializeError,
  },
);

const initialState: ConfigurationState = {
  configuration: null,
  serverErrors: [],
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setConfiguration(state: ConfigurationState, action: PayloadAction<AuditProjectResponse>) {
      state.configuration = action.payload;
    },
    resetConfiguration(state: ConfigurationState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfigurationByIdAction.pending, (state: ConfigurationState) => {
      state.serverErrors = [];
    });
    builder.addCase(fetchConfigurationByIdAction.fulfilled, (state: ConfigurationState, action) => {
      state.configuration = action.payload;
    });
    builder.addCase(fetchConfigurationByIdAction.rejected, (state: ConfigurationState, action) => {
      state.serverErrors = action.error.message ? [action.error.message] : [];
    });
    // Cleanup
    builder.addCase(resetStore, (state: ConfigurationState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setConfiguration, resetConfiguration } = configurationSlice.actions;

export default configurationSlice.reducer;
