import { dataTransformationApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { TanstackTableSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';
import {
  CreateProjectDto,
  UpdateProjectDto,
  ProjectDetailsResponse,
  ProjectStatus,
  ProjectRunResponsePagedResponse,
  ProjectResponsePagedResponse,
  ProjectRunModel,
  ProjectRunResponse,
  ProjectRunDetailResponse,
  ProjectAccessPermissionResponse,
  FlatFileParametersModel,
  RunStatus,
  UploadTypeDto,
  DataDictionaryResponse,
  DataDictionaryTableResponsePagedResponse,
  DataDictionaryTableDetailsResponse,
  AddDataDictionaryTableDto,
  UpdateDataDictionaryTableDto,
} from 'src/types';

export const createProject = (data: CreateProjectDto) => dataTransformationApi.post<string>('/api/v1/projects', data);

export const updateProject = (projectId: string, data: UpdateProjectDto) =>
  dataTransformationApi.patch<void>(`/api/v1/projects/${projectId}`, data);

export const updateProjectFlatFile = (projectId: string, data: FlatFileParametersModel) =>
  dataTransformationApi.post<void>(`/api/v1/projects/${projectId}/updateFlatFileParameters`, data);

export const getProjectById = (projectId: string, config: AxiosRequestConfig = {}) =>
  dataTransformationApi.get<ProjectDetailsResponse>(`/api/v1/projects/${projectId}`, config);

export const getProjectAccess = (projectId: string, config: AxiosRequestConfig = {}) =>
  dataTransformationApi.get<ProjectAccessPermissionResponse>(`/api/v1/projects/${projectId}/access`, config);

export const deleteProjectById = (projectId: string, config: AxiosRequestConfig = {}) =>
  dataTransformationApi.delete<void>(`/api/v1/projects/${projectId}`, config);

export type GetTemplateRunsHistoryByProjectIdParams = {
  status?: RunStatus;
} & TanstackTableSearchParamsAdapter;

export const getTemplateRunsHistoryByProjectId = (
  projectId: string,
  params: GetTemplateRunsHistoryByProjectIdParams,
  config: AxiosRequestConfig = {},
) =>
  dataTransformationApi.get<ProjectRunResponsePagedResponse>(`/api/v1/projects/${projectId}/runs`, {
    ...config,
    params,
  });

export type GetProjectListSearchParams = {
  companyId: string;
  status?: ProjectStatus;
} & TanstackTableSearchParamsAdapter;

export const getProjectListSearch = (params: GetProjectListSearchParams, config: AxiosRequestConfig = {}) =>
  dataTransformationApi.get<ProjectResponsePagedResponse>('/api/v1/projects/search', {
    ...config,
    params,
  });

export const getProjectRunDetails = (projectId: string, runId: string, config: AxiosRequestConfig = {}) =>
  dataTransformationApi.get<ProjectRunDetailResponse>(`/api/v1/projects/${projectId}/runs/${runId}`, config);

export const runProjectTemplates = (projectId: string, data: ProjectRunModel) =>
  dataTransformationApi.post<ProjectRunResponse>(`/api/v1/projects/${projectId}/run`, data);

/**
 * @returns ```
 * { ...response, status: 204, data: ''}
 * ```
 * if master data dictionary wasn't assigned
 */
export const getUploadType = (projectId: string, config: AxiosRequestConfig = {}) =>
  dataTransformationApi.get<UploadTypeDto | ''>(`/api/v1/projects/${projectId}/upload-type`, config);

export const updateUploadType = (projectId: string, data: UploadTypeDto) =>
  dataTransformationApi.post<void>(`/api/v1/projects/${projectId}/upload-type`, data);

//Data Dictionary

/**
 * @returns ```
 * { ...response, status: 204, data: ''}
 * ```
 * if master data dictionary wasn't assigned
 */
export const getProjectDataDictionary = (projectId: string, config: AxiosRequestConfig = {}) =>
  dataTransformationApi.get<DataDictionaryResponse | ''>(`/api/v1/projects/${projectId}/data-dictionary`, config);

export const getProjectDataDictionaryTableList = (
  projectId: string,
  params: TanstackTableSearchParamsAdapter,
  config: AxiosRequestConfig = {},
) =>
  dataTransformationApi.get<DataDictionaryTableResponsePagedResponse>(
    `/api/v1/projects/${projectId}/data-dictionary/tables`,
    { ...config, params },
  );

export const getProjectDataDictionaryTableDetails = (
  projectId: string,
  dataDictionaryTableId: string,
  config: AxiosRequestConfig = {},
) =>
  dataTransformationApi.get<DataDictionaryTableDetailsResponse>(
    `/api/v1/projects/${projectId}/data-dictionary/tables/${dataDictionaryTableId}/table-details`,
    config,
  );

export const assignMasterDataDictionaryToProject = (projectId: string, dataDictionaryId: string) =>
  dataTransformationApi.post<void>(`/api/v1/projects/${projectId}/data-dictionary/${dataDictionaryId}/assign`);

export const createProjectDataDictionaryTable = (
  projectId: string,
  data: AddDataDictionaryTableDto,
  config: AxiosRequestConfig = {},
) => dataTransformationApi.post<void>(`/api/v1/projects/${projectId}/data-dictionary/tables`, data, config);

export const updateProjectDataDictionaryTable = (
  projectId: string,
  data: UpdateDataDictionaryTableDto,
  config: AxiosRequestConfig = {},
) => dataTransformationApi.patch<void>(`/api/v1/projects/${projectId}/data-dictionary/tables`, data, config);

export const deleteProjectDataDictionaryTable = (
  projectId: string,
  dataDictionaryTableId: string,
  config: AxiosRequestConfig = {},
) =>
  dataTransformationApi.delete<void>(
    `/api/v1/projects/${projectId}/data-dictionary/tables/${dataDictionaryTableId}`,
    config,
  );

export const recreateProjectDatabase = (projectId: string) =>
  dataTransformationApi.post<void>(`/api/v1/projects/${projectId}/try-create-databases`);
