import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export const breadcrumbsSliceSelector = (state: RootState) => state.breadcrumbs;

export const breadcrumbsNamesMapSelector = createSelector(breadcrumbsSliceSelector, (state) => state.namesMap);

export const breadcrumbsParentPathMapSelector = createSelector(
  breadcrumbsSliceSelector,
  (state) => state.parentPathMap,
);

export const breadcrumbsSearchParamsMapSelector = createSelector(
  breadcrumbsSliceSelector,
  (state) => state.searchParamsMap,
);

export const breadcrumbsLocationStateMapSelector = createSelector(
  breadcrumbsSliceSelector,
  (state) => state.locationStateMap,
);
