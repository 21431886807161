export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',

  // Additional options
  additionalOptionsRoot: '/additional-options',

  // Additional options sql credentials
  additionalOptionsSqlCredentialsRoot: '/additional-options/sql-credentials',
  additionalOptionsSqlCredentialsUsers: '/additional-options/sql-credentials/users',

  // Additional options product info
  additionalOptionsProductInfoRoot: '/additional-options/product-info',
  additionalOptionsProductInfoDetails: '/additional-options/product-info/details',

  // Migration
  migrationRoot: '/migration',
  // Migration projects
  migrationProjectsRoot: '/migration/projects',
  migrationProjectsList: '/migration/projects/list',

  migrationProjectsCreateRoot: '/migration/projects/create',
  migrationProjectsCreateDetails: '/migration/projects/create/detail',
  migrationProjectsCreateDataDictionary: '/migration/projects/create/:projectId/data-dictionary',
  migrationProjectsCreateDatabases: '/migration/projects/create/:projectId/databases',
  migrationProjectsCreateTemplates: '/migration/projects/create/:projectId/templates',

  migrationProjectRoot: '/migration/projects/:projectId',
  migrationProjectDetails: '/migration/projects/:projectId/details',

  migrationProjectDataDictionaryRoot: '/migration/projects/:projectId/data-dictionary',
  migrationProjectDataDictionarySourceData: '/migration/projects/:projectId/data-dictionary/source-data',
  migrationProjectDataDictionaryDefinitionRoot: '/migration/projects/:projectId/data-dictionary/data-definition',
  migrationProjectDataDictionaryDefinitionTableList:
    '/migration/projects/:projectId/data-dictionary/data-definition/tables',
  migrationProjectDataDictionaryDefinitionTableDetails:
    '/migration/projects/:projectId/data-dictionary/data-definition/tables/:dataDictionaryTableId/details',
  migrationProjectDataDictionaryBulkUpdateRoot: '/migration/projects/:projectId/data-dictionary/bulk-update',
  migrationProjectDataDictionaryBulkUpdateUploadData:
    '/migration/projects/:projectId/data-dictionary/bulk-update/upload-data',
  migrationProjectDataDictionaryBulkUpdateReviewRoot:
    '/migration/projects/:projectId/data-dictionary/bulk-update/review',
  migrationProjectDataDictionaryBulkUpdateReviewTableList:
    '/migration/projects/:projectId/data-dictionary/bulk-update/review/tables',
  migrationProjectDataDictionaryBulkUpdateReviewTableDetails:
    '/migration/projects/:projectId/data-dictionary/bulk-update/review/tables/:dataDictionaryTableId/details',

  migrationProjectDatabasesRoot: '/migration/projects/:projectId/databases',
  migrationProjectDatabasesCurrent: '/migration/projects/:projectId/databases/current',
  migrationProjectDatabasesHistoryRoot: '/migration/projects/:projectId/databases/history',
  migrationProjectDatabasesHistoryList: '/migration/projects/:projectId/databases/history/list',
  migrationProjectDatabasesHistoryDetails: '/migration/projects/:projectId/databases/history/:historyId/details',
  migrationProjectDatabasesHistoryDetailsTableDetails:
    '/migration/projects/:projectId/databases/history/:historyId/table/:tableId/details',
  migrationProjectDatabasesUpdateSource: '/migration/projects/:projectId/databases/:restoreType/update-source',
  migrationProjectDatabasesTables: '/migration/projects/:projectId/databases/tables',

  migrationProjectTemplatesRoot: '/migration/projects/:projectId/templates',
  migrationProjectTemplatesList: '/migration/projects/:projectId/templates/assigned',
  migrationProjectTemplatesAssign: '/migration/projects/:projectId/templates/assigned/add',
  migrationProjectTemplatesCreate: '/migration/projects/:projectId/templates/create',

  migrationProjectTemplatesEditRoot: '/migration/projects/:projectId/templates/:templateId/edit',
  migrationProjectTemplatesEditDetails: '/migration/projects/:projectId/templates/:templateId/edit/details',
  migrationProjectTemplatesEditScript: '/migration/projects/:projectId/templates/:templateId/edit/scripts',
  migrationProjectTemplatesEditReadOnly: '/migration/projects/:projectId/templates/:templateId/edit/read-only',

  migrationProjectTemplatesScriptCreate:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/edit/create',
  // Migration projects script -> edit
  migrationProjectTemplatesScriptEditRoot:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/edit/:scriptId',
  migrationProjectTemplatesScriptEditUpdate:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/edit/:scriptId/edit',
  migrationProjectTemplatesScriptEditDetails:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/edit/:scriptId/details',
  migrationProjectTemplatesScriptEditHistory:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/edit/:scriptId/history',
  migrationProjectTemplatesScriptEditLockAudit:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/edit/:scriptId/lock-audit',
  migrationProjectTemplatesScriptEditVersion:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/edit/:scriptId/version',
  migrationProjectTemplatesScriptEditInfo:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/edit/:scriptId/info',
  // Migration projects script -> view
  migrationProjectTemplatesScriptViewRoot:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/view/:scriptId',
  migrationProjectTemplatesScriptViewUpdate:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/view/:scriptId/edit',
  migrationProjectTemplatesScriptViewDetails:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/view/:scriptId/details',
  migrationProjectTemplatesScriptViewHistory:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/view/:scriptId/history',
  migrationProjectTemplatesScriptViewLockAudit:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/view/:scriptId/lock-audit',
  migrationProjectTemplatesScriptViewVersion:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/view/:scriptId/version',
  migrationProjectTemplatesScriptViewInfo:
    '/migration/projects/:projectId/templates/:templateId/level/:levelId/script/view/:scriptId/info',

  migrationProjectTemplatesRunHistoryRoot: '/migration/projects/:projectId/templates/run-history',
  migrationProjectTemplatesRunHistoryList: '/migration/projects/:projectId/templates/run-history/list',
  migrationProjectTemplatesRunHistoryDetails: '/migration/projects/:projectId/templates/run-history/:runId/details',

  migrationProjectUsersRoot: '/migration/projects/:projectId/users',
  migrationProjectUsersProjectAccess: '/migration/projects/:projectId/users/project-access',
  migrationProjectUsersSQLPermissions: '/migration/projects/:projectId/users/sql-permissions',

  migrationProjectBackgroundProcess: '/migration/projects/:projectId/background-process',

  // Master templates
  masterTemplatesRoot: '/migration/master-templates',
  masterTemplatesList: '/migration/master-templates/list',
  masterTemplatesCreate: '/migration/master-templates/create',
  masterTemplatesEditRoot: '/migration/master-templates/:templateId/edit',
  masterTemplatesEditDetails: '/migration/master-templates/:templateId/edit/details',
  masterTemplatesEditScript: '/migration/master-templates/:templateId/edit/scripts',
  masterTemplatesEditReadOnly: '/migration/master-templates/:templateId/edit/read-only',

  masterTemplatesScriptCreate: '/migration/master-templates/:templateId/level/:levelId/script/edit/create',
  // Master templates script -> edit
  masterTemplatesScriptEditRoot: '/migration/master-templates/:templateId/level/:levelId/script/edit/:scriptId',
  masterTemplatesScriptEditUpdate: '/migration/master-templates/:templateId/level/:levelId/script/edit/:scriptId/edit',
  masterTemplatesScriptEditDetails:
    '/migration/master-templates/:templateId/level/:levelId/script/edit/:scriptId/details',
  masterTemplatesScriptEditHistory:
    '/migration/master-templates/:templateId/level/:levelId/script/edit/:scriptId/history',
  masterTemplatesScriptEditLockAudit:
    '/migration/master-templates/:templateId/level/:levelId/script/edit/:scriptId/lock-audit',
  masterTemplatesScriptEditVersion:
    '/migration/master-templates/:templateId/level/:levelId/script/edit/:scriptId/version',
  masterTemplatesScriptEditInfo: '/migration/master-templates/:templateId/level/:levelId/script/edit/:scriptId/info',
  // Master templates  script -> view
  masterTemplatesScriptViewRoot: '/migration/master-templates/:templateId/level/:levelId/script/view/:scriptId',
  masterTemplatesScriptViewUpdate:
    '/migration/master-templates/:templateId/level/:levelId/script/view/:scriptId/update',
  masterTemplatesScriptViewDetails:
    '/migration/master-templates/:templateId/level/:levelId/script/view/:scriptId/details',
  masterTemplatesScriptViewHistory:
    '/migration/master-templates/:templateId/level/:levelId/script/view/:scriptId/history',
  masterTemplatesScriptViewLockAudit:
    '/migration/master-templates/:templateId/level/:levelId/script/view/:scriptId/lock-audit',
  masterTemplatesScriptViewVersion:
    '/migration/master-templates/:templateId/level/:levelId/script/view/:scriptId/version',
  masterTemplatesScriptViewInfo: '/migration/master-templates/:templateId/level/:levelId/script/view/:scriptId/info',

  // Master data dictionary
  masterDataDictionaryRoot: '/migration/data-dictionary',
  masterDataDictionaryList: '/migration/data-dictionary/list',
  // Create
  masterDataDictionaryCreateRoot: '/migration/data-dictionary/create',
  masterDataDictionaryCreateApplication: '/migration/data-dictionary/create/:dataDictionaryId/application',
  masterDataDictionaryCreateUploadData: '/migration/data-dictionary/create/:dataDictionaryId/upload-data',
  masterDataDictionaryCreateReviewRoot: '/migration/data-dictionary/create/:dataDictionaryId/review',
  masterDataDictionaryCreateReviewTableList: '/migration/data-dictionary/create/:dataDictionaryId/review/tables',
  masterDataDictionaryCreateReviewTableDetails:
    '/migration/data-dictionary/create/:dataDictionaryId/review/tables/:dataDictionaryTableId/details',
  // Edit
  masterDataDictionaryEditRoot: '/migration/data-dictionary/edit/:dataDictionaryId',
  masterDataDictionaryEditApplication: '/migration/data-dictionary/edit/:dataDictionaryId/application',
  masterDataDictionaryEditDataDefinitionRoot: '/migration/data-dictionary/edit/:dataDictionaryId/data-definition',
  masterDataDictionaryEditDataDefinitionTableList:
    '/migration/data-dictionary/edit/:dataDictionaryId/data-definition/tables',
  masterDataDictionaryEditDataDefinitionTableDetails:
    '/migration/data-dictionary/edit/:dataDictionaryId/data-definition/tables/:dataDictionaryTableId/details',
  masterDataDictionaryEditBulkUpdateRoot: '/migration/data-dictionary/edit/:dataDictionaryId/bulk-update',
  masterDataDictionaryEditBulkUpdateUploadData:
    '/migration/data-dictionary/edit/:dataDictionaryId/bulk-update/upload-data',
  masterDataDictionaryEditBulkUpdateReviewRoot: '/migration/data-dictionary/edit/:dataDictionaryId/bulk-update/review',
  masterDataDictionaryEditBulkUpdateReviewTableList:
    '/migration/data-dictionary/edit/:dataDictionaryId/bulk-update/review/tables',
  masterDataDictionaryEditBulkUpdateReviewTableDetails:
    '/migration/data-dictionary/edit/:dataDictionaryId/bulk-update/review/tables/:dataDictionaryTableId/details',
  // View
  masterDataDictionaryViewRoot: '/migration/data-dictionary/view/:dataDictionaryId',
  masterDataDictionaryViewApplication: '/migration/data-dictionary/view/:dataDictionaryId/application',
  masterDataDictionaryViewDataDefinitionRoot: '/migration/data-dictionary/view/:dataDictionaryId/data-definition',
  masterDataDictionaryViewDataDefinitionTableList:
    '/migration/data-dictionary/view/:dataDictionaryId/data-definition/tables',
  masterDataDictionaryViewDataDefinitionTableDetails:
    '/migration/data-dictionary/view/:dataDictionaryId/data-definition/tables/:dataDictionaryTableId/details',

  applicationRoot: '/migration/application',
  applicationList: '/migration/application/list',
  applicationCreate: '/migration/application/create',

  // Translations
  translationsRoot: '/translations',
  // Translations Mappings
  translationsMappingsRoot: '/translations/mappings',
  translationsMappingsList: '/translations/mappings/list',

  translationsMappingsCreateRoot: '/translations/mappings/create',
  translationsMappingsCreateBasicSettings: '/translations/mappings/create/new/basic-settings',
  translationsMappingsCreateFileDataStructure: '/translations/mappings/create/:mappingProjectId/file-data-structure',
  translationsMappingsCreateTemplates: '/translations/mappings/create/:mappingProjectId/templates',

  translationsMappingProjectRoot: '/translations/mappings/:mappingProjectId',
  translationsMappingProjectDetails: '/translations/mappings/:mappingProjectId/details',

  translationsMappingProjectFileStructure: '/translations/mappings/:mappingProjectId/file-data-structure',
  translationsMappingProjectFileStructureTable:
    '/translations/mappings/:mappingProjectId/file-data-structure/mappingTable/:mappingSourceTableId',
  translationsMappingProjectFileStructureColumn:
    '/translations/mappings/:mappingProjectId/file-data-structure/mappingTable/:mappingSourceTableId/column/:columnId',

  translationsMappingProjectTemplatesRoot: '/translations/mappings/:mappingProjectId/templates',
  translationsMappingProjectTemplatesList: '/translations/mappings/:mappingProjectId/templates/assigned',
  translationsMappingProjectTemplatesAssign: '/translations/mappings/:mappingProjectId/templates/assigned/add',
  translationsMappingProjectTemplatesCreate: '/translations/mappings/:mappingProjectId/templates/create',
  translationsMappingProjectTemplatesEditRoot: '/translations/mappings/:mappingProjectId/templates/:templateId/edit',
  translationsMappingProjectTemplatesEditScript:
    '/translations/mappings/:mappingProjectId/templates/:templateId/edit/scripts',
  translationsMappingProjectTemplatesEditReadOnly:
    '/translations/mappings/:mappingProjectId/templates/:templateId/edit/read-only',
  translationsMappingProjectTemplatesEditDetails:
    '/translations/mappings/:mappingProjectId/templates/:templateId/edit/details',

  translationsMappingProjectTemplatesScriptCreate:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/edit/create',
  // Translations Mappings script -> edit
  translationsMappingProjectTemplatesScriptEditRoot:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/edit/:scriptId',
  translationsMappingProjectTemplatesScriptEditUpdate:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/edit/:scriptId/edit',
  translationsMappingProjectTemplatesScriptEditHistory:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/edit/:scriptId/history',
  translationsMappingProjectTemplatesScriptEditLockAudit:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/edit/:scriptId/lock-audit',
  translationsMappingProjectTemplatesScriptEditVersion:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/edit/:scriptId/version',
  translationsMappingProjectTemplatesScriptEditInfo:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/edit/:scriptId/info',
  translationsMappingProjectTemplatesScriptEditDetails:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/edit/:scriptId/details',
  // Translations Mappings script -> view
  translationsMappingProjectTemplatesScriptViewRoot:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/view/:scriptId',
  translationsMappingProjectTemplatesScriptViewUpdate:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/view/:scriptId/edit',
  translationsMappingProjectTemplatesScriptViewHistory:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/view/:scriptId/history',
  translationsMappingProjectTemplatesScriptViewLockAudit:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/view/:scriptId/lock-audit',
  translationsMappingProjectTemplatesScriptViewVersion:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/view/:scriptId/version',
  translationsMappingProjectTemplatesScriptViewInfo:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/view/:scriptId/info',
  translationsMappingProjectTemplatesScriptViewDetails:
    '/translations/mappings/:mappingProjectId/templates/:templateId/level/:levelId/script/view/:scriptId/details',

  //Translations MasterTemplates
  translationsMasterTemplatesRoot: '/translations/master-templates',
  translationsMasterTemplatesList: '/translations/master-templates/list',
  translationsMasterTemplatesCreate: '/translations/master-templates/create',
  translationsMasterTemplatesReadOnly: '/translations/master-templates/:templateId/edit/read-only',
  translationsMasterTemplatesEditRoot: '/translations/master-templates/:templateId/edit',
  translationsMasterTemplatesEditDetails: '/translations/master-templates/:templateId/edit/details',
  translationsMasterTemplatesEditScript: '/translations/master-templates/:templateId/edit/scripts',
  translationsMasterTemplatesEditReadOnly: '/translations/master-templates/:templateId/edit/read-only',
  translationsMasterTemplatesScriptCreate:
    '/translations/master-templates/:templateId/level/:levelId/script/edit/create',
  //Translations MasterTemplates script -> edit
  translationsMasterTemplatesScriptEditRoot:
    '/translations/master-templates/:templateId/level/:levelId/script/edit/:scriptId',
  translationsMasterTemplatesScriptEditUpdate:
    '/translations/master-templates/:templateId/level/:levelId/script/edit/:scriptId/edit',
  translationsMasterTemplatesScriptEditDetails:
    '/translations/master-templates/:templateId/level/:levelId/script/edit/:scriptId/details',
  translationsMasterTemplatesScriptEditHistory:
    '/translations/master-templates/:templateId/level/:levelId/script/edit/:scriptId/history',
  translationsMasterTemplatesScriptEditLockAudit:
    '/translations/master-templates/:templateId/level/:levelId/script/edit/:scriptId/lock-audit',
  translationsMasterTemplatesScriptEditVersion:
    '/translations/master-templates/:templateId/level/:levelId/script/edit/:scriptId/version',
  translationsMasterTemplatesScriptEditInfo:
    '/translations/master-templates/:templateId/level/:levelId/script/edit/:scriptId/info',
  //Translations MasterTemplates script -> view
  translationsMasterTemplatesScriptViewRoot:
    '/translations/master-templates/:templateId/level/:levelId/script/view/:scriptId',
  translationsMasterTemplatesScriptViewUpdate:
    '/translations/master-templates/:templateId/level/:levelId/script/view/:scriptId/edit',
  translationsMasterTemplatesScriptViewDetails:
    '/translations/master-templates/:templateId/level/:levelId/script/view/:scriptId/details',
  translationsMasterTemplatesScriptViewHistory:
    '/translations/master-templates/:templateId/level/:levelId/script/view/:scriptId/history',
  translationsMasterTemplatesScriptViewLockAudit:
    '/translations/master-templates/:templateId/level/:levelId/script/view/:scriptId/lock-audit',
  translationsMasterTemplatesScriptViewVersion:
    '/translations/master-templates/:templateId/level/:levelId/script/view/:scriptId/version',
  translationsMasterTemplatesScriptViewInfo:
    '/translations/master-templates/:templateId/level/:levelId/script/view/:scriptId/info',

  // Onboarding
  onboardingRoot: '/onboarding',
  onboardingConfigurationsRoot: '/onboarding/configurations',
  onboardingConfigurationsList: '/onboarding/configurations/list',

  // Onboarding Configuration create
  onboardingConfigurationCreateRoot: '/onboarding/configurations/create',
  onboardingConfigurationCreateDetails: '/onboarding/configurations/create/detail',
  onboardingConfigurationCreateDataDictionary: '/onboarding/configurations/create/:configurationId/data-dictionary',
  onboardingConfigurationCreateTemplates: '/onboarding/configurations/create/:configurationId/templates',

  onboardingConfigurationRoot: '/onboarding/configurations/:configurationId',
  onboardingConfigurationDetails: '/onboarding/configurations/:configurationId/details',

  onboardingConfigurationDataDictionaryRoot: '/onboarding/configurations/:configurationId/data-dictionary',
  onboardingConfigurationDataDictionarySourceData:
    '/onboarding/configurations/:configurationId/data-dictionary/source-data',
  onboardingConfigurationDataDictionaryDefinitionRoot:
    '/onboarding/configurations/:configurationId/data-dictionary/data-definition',
  onboardingConfigurationDataDictionaryDefinitionTableList:
    '/onboarding/configurations/:configurationId/data-dictionary/data-definition/tables',
  onboardingConfigurationDataDictionaryDefinitionTableDetails:
    '/onboarding/configurations/:configurationId/data-dictionary/data-definition/tables/:dataDictionaryTableId/details',
  onboardingConfigurationDataDictionaryBulkUpdateRoot:
    '/onboarding/configurations/:configurationId/data-dictionary/bulk-update',
  onboardingConfigurationDataDictionaryBulkUpdateUploadData:
    '/onboarding/configurations/:configurationId/data-dictionary/bulk-update/upload-data',
  onboardingConfigurationDataDictionaryBulkUpdateReviewRoot:
    '/onboarding/configurations/:configurationId/data-dictionary/bulk-update/review',
  onboardingConfigurationDataDictionaryBulkUpdateReviewTableList:
    '/onboarding/configurations/:configurationId/data-dictionary/bulk-update/review/tables',
  onboardingConfigurationDataDictionaryBulkUpdateReviewTableDetails:
    '/onboarding/configurations/:configurationId/data-dictionary/bulk-update/review/tables/:dataDictionaryTableId/details',

  onboardingConfigurationDatabasesRoot: '/onboarding/configurations/:configurationId/databases',
  onboardingConfigurationDatabasesCurrent: '/onboarding/configurations/:configurationId/databases/current',
  onboardingConfigurationDatabasesHistoryRoot: '/onboarding/configurations/:configurationId/databases/history',
  onboardingConfigurationDatabasesHistoryList: '/onboarding/configurations/:configurationId/databases/history/list',
  onboardingConfigurationDatabasesHistoryDetails:
    '/onboarding/configurations/:configurationId/databases/history/:historyId/details',
  onboardingConfigurationDatabasesHistoryDetailsTableDetails:
    '/onboarding/configurations/:configurationId/databases/history/:historyId/table/:tableId/details',
  onboardingConfigurationDatabasesUpdateSource:
    '/onboarding/configurations/:configurationId/databases/:restoreType/update-source',
  onboardingConfigurationDatabasesCompareSource: '/onboarding/configurations/:configurationId/databases/compare-source',

  onboardingConfigurationTemplatesRoot: '/onboarding/configurations/:configurationId/templates',
  onboardingConfigurationTemplatesList: '/onboarding/configurations/:configurationId/templates/assigned',
  onboardingConfigurationTemplatesAssign: '/onboarding/configurations/:configurationId/templates/assigned/add',
  onboardingConfigurationTemplatesCreate: '/onboarding/configurations/:configurationId/templates/create',
  onboardingConfigurationTemplatesRunHistoryRoot: '/onboarding/configurations/:configurationId/templates/run-history',
  onboardingConfigurationTemplatesRunHistoryList:
    '/onboarding/configurations/:configurationId/templates/run-history/list',
  onboardingConfigurationTemplatesRunHistoryDetails:
    '/onboarding/configurations/:configurationId/templates/run-history/:runId/details',
  onboardingConfigurationTemplatesEditRoot: '/onboarding/configurations/:configurationId/templates/:templateId/edit',
  onboardingConfigurationTemplatesEditDetails:
    '/onboarding/configurations/:configurationId/templates/:templateId/edit/details',
  onboardingConfigurationTemplatesEditScript:
    '/onboarding/configurations/:configurationId/templates/:templateId/edit/scripts',
  onboardingConfigurationTemplatesEditReadOnly:
    '/onboarding/configurations/:configurationId/templates/:templateId/edit/read-only',

  onboardingConfigurationTemplatesScriptCreate:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/edit/create',
  // Onboarding configuration script -> edit
  onboardingConfigurationTemplatesScriptEditRoot:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/edit/:scriptId',
  onboardingConfigurationTemplatesScriptEditUpdate:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/edit/:scriptId/edit',
  onboardingConfigurationTemplatesScriptEditDetails:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/edit/:scriptId/details',
  onboardingConfigurationTemplatesScriptEditHistory:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/edit/:scriptId/history',
  onboardingConfigurationTemplatesScriptEditLockAudit:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/edit/:scriptId/lock-audit',
  onboardingConfigurationTemplatesScriptEditVersion:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/edit/:scriptId/version',
  onboardingConfigurationTemplatesScriptEditInfo:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/edit/:scriptId/info',
  // Onboarding configuration -> view
  onboardingConfigurationTemplatesScriptViewRoot:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/view/:scriptId',
  onboardingConfigurationTemplatesScriptViewUpdate:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/view/:scriptId/edit',
  onboardingConfigurationTemplatesScriptViewDetails:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/view/:scriptId/details',
  onboardingConfigurationTemplatesScriptViewHistory:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/view/:scriptId/history',
  onboardingConfigurationTemplatesScriptViewLockAudit:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/view/:scriptId/lock-audit',
  onboardingConfigurationTemplatesScriptViewVersion:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/view/:scriptId/version',
  onboardingConfigurationTemplatesScriptViewInfo:
    '/onboarding/configurations/:configurationId/templates/:templateId/level/:levelId/script/view/:scriptId/info',

  onboardingConfigurationUsersRoot: '/onboarding/configurations/:configurationId/users',
  onboardingConfigurationUsersProjectAccess: '/onboarding/configurations/:configurationId/users/project-access',
  onboardingConfigurationUsersSQLPermissions: '/onboarding/configurations/:configurationId/users/sql-permissions',

  onboardingConfigurationBackgroundProcess: '/onboarding/configurations/:configurationId/background-process',

  //Onboarding Master Templates
  onboardingMasterTemplatesRoot: '/onboarding/master-templates',
  onboardingMasterTemplatesList: '/onboarding/master-templates/list',
  onboardingMasterTemplatesCreate: '/onboarding/master-templates/create',
  onboardingMasterTemplatesReadOnly: '/onboarding/master-templates/:templateId/edit/read-only',
  onboardingMasterTemplatesEditRoot: '/onboarding/master-templates/:templateId/edit',
  onboardingMasterTemplatesEditDetails: '/onboarding/master-templates/:templateId/edit/details',
  onboardingMasterTemplatesEditScript: '/onboarding/master-templates/:templateId/edit/scripts',
  onboardingMasterTemplatesEditReadOnly: '/onboarding/master-templates/:templateId/edit/read-only',
  onboardingMasterTemplatesScriptCreate: '/onboarding/master-templates/:templateId/level/:levelId/script/edit/create',
  //Onboarding MasterTemplates script -> edit
  onboardingMasterTemplatesScriptEditRoot:
    '/onboarding/master-templates/:templateId/level/:levelId/script/edit/:scriptId',
  onboardingMasterTemplatesScriptEditUpdate:
    '/onboarding/master-templates/:templateId/level/:levelId/script/edit/:scriptId/edit',
  onboardingMasterTemplatesScriptEditDetails:
    '/onboarding/master-templates/:templateId/level/:levelId/script/edit/:scriptId/details',
  onboardingMasterTemplatesScriptEditHistory:
    '/onboarding/master-templates/:templateId/level/:levelId/script/edit/:scriptId/history',
  onboardingMasterTemplatesScriptEditLockAudit:
    '/onboarding/master-templates/:templateId/level/:levelId/script/edit/:scriptId/lock-audit',
  onboardingMasterTemplatesScriptEditVersion:
    '/onboarding/master-templates/:templateId/level/:levelId/script/edit/:scriptId/version',
  onboardingMasterTemplatesScriptEditInfo:
    '/onboarding/master-templates/:templateId/level/:levelId/script/edit/:scriptId/info',
  //Onboarding MasterTemplates script -> view
  onboardingMasterTemplatesScriptViewRoot:
    '/onboarding/master-templates/:templateId/level/:levelId/script/view/:scriptId',
  onboardingMasterTemplatesScriptViewUpdate:
    '/onboarding/master-templates/:templateId/level/:levelId/script/view/:scriptId/edit',
  onboardingMasterTemplatesScriptViewDetails:
    '/onboarding/master-templates/:templateId/level/:levelId/script/view/:scriptId/details',
  onboardingMasterTemplatesScriptViewHistory:
    '/onboarding/master-templates/:templateId/level/:levelId/script/view/:scriptId/history',
  onboardingMasterTemplatesScriptViewLockAudit:
    '/onboarding/master-templates/:templateId/level/:levelId/script/view/:scriptId/lock-audit',
  onboardingMasterTemplatesScriptViewVersion:
    '/onboarding/master-templates/:templateId/level/:levelId/script/view/:scriptId/version',
  onboardingMasterTemplatesScriptViewInfo:
    '/onboarding/master-templates/:templateId/level/:levelId/script/view/:scriptId/info',

  //Onboarding Master Dictionary
  onboardingMasterDataDictionaryRoot: '/onboarding/data-dictionary',
  onboardingMasterDataDictionaryList: '/onboarding/data-dictionary/list',

  // Create
  onboardingMasterDataDictionaryCreateRoot: '/onboarding/data-dictionary/create',
  onboardingMasterDataDictionaryCreateApplication: '/onboarding/data-dictionary/create/:dataDictionaryId/application',
  onboardingMasterDataDictionaryCreateUploadData: '/onboarding/data-dictionary/create/:dataDictionaryId/upload-data',
  onboardingMasterDataDictionaryCreateReviewRoot: '/onboarding/data-dictionary/create/:dataDictionaryId/review',
  onboardingMasterDataDictionaryCreateReviewTableList:
    '/onboarding/data-dictionary/create/:dataDictionaryId/review/tables',
  onboardingMasterDataDictionaryCreateReviewTableDetails:
    '/onboarding/data-dictionary/create/:dataDictionaryId/review/tables/:dataDictionaryTableId/details',
  // Edit
  onboardingMasterDataDictionaryEditRoot: '/onboarding/data-dictionary/edit/:dataDictionaryId',
  onboardingMasterDataDictionaryEditApplication: '/onboarding/data-dictionary/edit/:dataDictionaryId/application',
  onboardingMasterDataDictionaryEditDataDefinitionRoot:
    '/onboarding/data-dictionary/edit/:dataDictionaryId/data-definition',
  onboardingMasterDataDictionaryEditDataDefinitionTableList:
    '/onboarding/data-dictionary/edit/:dataDictionaryId/data-definition/tables',
  onboardingMasterDataDictionaryEditDataDefinitionTableDetails:
    '/onboarding/data-dictionary/edit/:dataDictionaryId/data-definition/tables/:dataDictionaryTableId/details',
  onboardingMasterDataDictionaryEditBulkUpdateRoot: '/onboarding/data-dictionary/edit/:dataDictionaryId/bulk-update',
  onboardingMasterDataDictionaryEditBulkUpdateUploadData:
    '/onboarding/data-dictionary/edit/:dataDictionaryId/bulk-update/upload-data',
  onboardingMasterDataDictionaryEditBulkUpdateReviewRoot:
    '/onboarding/data-dictionary/edit/:dataDictionaryId/bulk-update/review',
  onboardingMasterDataDictionaryEditBulkUpdateReviewTableList:
    '/onboarding/data-dictionary/edit/:dataDictionaryId/bulk-update/review/tables',
  onboardingMasterDataDictionaryEditBulkUpdateReviewTableDetails:
    '/onboarding/data-dictionary/edit/:dataDictionaryId/bulk-update/review/tables/:dataDictionaryTableId/details',
  // View
  onboardingMasterDataDictionaryViewRoot: '/onboarding/data-dictionary/view/:dataDictionaryId',
  onboardingMasterDataDictionaryViewApplication: '/onboarding/data-dictionary/view/:dataDictionaryId/application',
  onboardingMasterDataDictionaryViewDataDefinitionRoot:
    '/onboarding/data-dictionary/view/:dataDictionaryId/data-definition',
  onboardingMasterDataDictionaryViewDataDefinitionTableList:
    '/onboarding/data-dictionary/view/:dataDictionaryId/data-definition/tables',
  onboardingMasterDataDictionaryViewDataDefinitionTableDetails:
    '/onboarding/data-dictionary/view/:dataDictionaryId/data-definition/tables/:dataDictionaryTableId/details',
};

export { GuardedRoute } from './GuardedRoute';
