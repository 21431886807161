import { Converter } from '@itm/shared-frontend/lib/utils';
import getEnvVariable from './getEnvVariable';

import {
  TargetPlatform,
  SelectOption,
  RunStatus,
  ProjectStatus,
  MappingProjectStatus,
  ProcessType,
  MappingProjectType,
  LockStatus,
  DataDictionaryStatus,
  ProjectUploadType,
  FlatFileUploadType,
  ProjectDefault,
  AuditTemplateType,
  RunType,
  DatabaseRestoreType,
  EmailNotifications,
} from 'src/types';

export const ENV_PREFIX = 'REACT_APP_' as const;

export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const TARGET_PLATFORM = getEnvVariable('TARGET_PLATFORM');
export const CLIENT_PORTAL_API_URL = getEnvVariable('CLIENT_PORTAL_API_URL');
export const DATA_TRANSFORMATION_API_URL = getEnvVariable('DATA_TRANSFORMATION_API_URL');

export const isAzureTargetPlatform = TARGET_PLATFORM === TargetPlatform.AZURE;

export const runStatusOptions = Converter.enumToSelectOptions(RunStatus);
export const runTypeOptions = Converter.enumToSelectOptions(RunType);
export const processTypeOptions = Converter.enumToSelectOptions(ProcessType);
export const projectStatusOptionsFull = Converter.enumToSelectOptions(ProjectStatus);
export const mappingProjectStatusOptions = Converter.enumToSelectOptions(MappingProjectStatus);
export const mappingProjectTypeOptions = Converter.enumToSelectOptions(MappingProjectType);
export const scriptEditLockStatusOptions = Converter.enumToSelectOptions(LockStatus);
export const dataDictionaryStatusOptions = Converter.enumToSelectOptions(DataDictionaryStatus);
export const migrationProjectUploadTypeOptions = Converter.enumToSelectOptions(ProjectUploadType);
export const flatFileUploadTypeOptions = Converter.enumToSelectOptions(FlatFileUploadType);
export const configurationProjectDefaultOptions = Converter.enumToSelectOptions(ProjectDefault);
export const configurationMasterTemplateTypeOptions = Converter.enumToSelectOptions(AuditTemplateType);
export const databaseRestoreTypeOptions = Converter.enumToSelectOptions(DatabaseRestoreType);
export const projectEmailsNotificationOptions = Converter.enumToSelectOptions(EmailNotifications);

export const onboardingConfigurationAdaptedStatusOptions: SelectOption[] = [
  { value: ProjectStatus.Active, label: ProjectStatus.Active },
  { value: ProjectStatus.SetupDataDictionaryIncomplete, label: 'Data dictionary not defined' },
  { value: ProjectStatus.SetupAssignTemplatesIncomplete, label: 'Template not assigned' },
  { value: ProjectStatus.SetupUploadTypeIncomplete, label: 'Upload type not defined' },
];

export const dataDictionaryTableSQLDataTypeList = [
  'int',
  'decimal',
  'float',
  'bigint',
  'date',
  'time',
  'datetime',
  'nvarchar',
  'uniqueidentifier',
];

export const dataDictionaryTableSQLDataTypeOptions: SelectOption[] = dataDictionaryTableSQLDataTypeList.map((v) => ({
  label: v,
  value: v,
}));

export const flatFileExtensionTextValue = '.txt';
export const flatFileExtensionExcelValue = '.xlsx';
export const flatFileExtensionOptions: SelectOption[] = [
  { value: '.csv', label: '.CSV' },
  { value: flatFileExtensionTextValue, label: flatFileExtensionTextValue.toLocaleUpperCase() },
  { value: '.dta', label: '.DTA' },
  { value: flatFileExtensionExcelValue, label: flatFileExtensionExcelValue.toLocaleUpperCase() },
];

export const flatFileDataSeparatorAutoDetectValue = 'Auto detect';
export const flatFileDataSeparatorFixedLengthValue = 'F';
export const flatFileDataSeparatorExcelValue = 'E';

export const flatFileDataSeparatorTypeOptions: SelectOption[] = [
  { value: flatFileDataSeparatorAutoDetectValue, label: flatFileDataSeparatorAutoDetectValue },
  { value: flatFileDataSeparatorFixedLengthValue, label: 'Fixed Length' },
  { value: flatFileDataSeparatorExcelValue, label: 'Excel' },
  { value: '\t', label: 'Tab' },
  { value: ',', label: ',' },
  { value: '|', label: '|' },
  { value: ';', label: ';' },
  { value: '~', label: '~' },
  { value: "'", label: "'" },
  { value: '^', label: '^' },
];
