import { dataTransformationApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { TanstackTableSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';

import {
  ProjectStatus,
  SearchAuditProjectResponsePagedResponse,
  AuditProjectResponse,
  CreateAuditProjectDto,
  UpdateAuditProjectDto,
  AuditDatabaseResponsePagedResponse,
} from 'src/types';

export type GetAuditConfigurationListSearchParams = {
  companyId: string;
  schemeId?: string;
  status?: ProjectStatus;
} & TanstackTableSearchParamsAdapter;

export const getAuditConfigurationListSearch = (
  params: GetAuditConfigurationListSearchParams,
  config: AxiosRequestConfig = {},
) =>
  dataTransformationApi.get<SearchAuditProjectResponsePagedResponse>('/api/v1/audit-projects/search', {
    ...config,
    params,
  });

export const getAuditProjectById = (projectId: string, config: AxiosRequestConfig = {}) =>
  dataTransformationApi.get<AuditProjectResponse>(`/api/v1/audit-projects/${projectId}`, config);

export const createAuditProject = (data: CreateAuditProjectDto) =>
  dataTransformationApi.post<string>('/api/v1/audit-projects', data);

export const updateAuditProject = (projectId: string, data: UpdateAuditProjectDto) =>
  dataTransformationApi.patch<void>(`/api/v1/audit-projects/${projectId}`, data);

export const checkCompanyAdminDataSourceHasDefaultProject = (
  companyAdminDataSourceId: string,
  config: AxiosRequestConfig = {},
) =>
  dataTransformationApi.get<boolean>(`/api/v1/audit-projects/checkdefaultproject/${companyAdminDataSourceId}`, config);

export type GetConfigurationDatabaseListSearchParams = {
  schemeId?: string;
} & TanstackTableSearchParamsAdapter;

export const getConfigurationDatabaseListSearch = (
  projectId: string,
  params: GetConfigurationDatabaseListSearchParams,
  config: AxiosRequestConfig = {},
) =>
  dataTransformationApi.get<AuditDatabaseResponsePagedResponse>(`/api/v1/audit-projects/${projectId}/databases`, {
    ...config,
    params,
  });
