import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export const projectPermissionsSliceSelector = (state: RootState) => state.projectPermissions;

export const userPermissionsSelector = createSelector(
  projectPermissionsSliceSelector,
  (state) => state.userPermissions,
);

export const isEditProjectPermissionSelector = createSelector(userPermissionsSelector, (permissions) =>
  permissions ? permissions.canWrite : false,
);
export const isViewProjectPermissionSelector = createSelector(userPermissionsSelector, (permissions) =>
  permissions ? permissions.canRead : false,
);

export const projectPermissionsServerErrorsSelector = createSelector(
  projectPermissionsSliceSelector,
  (state) => state.serverErrors,
);
