import { dataTransformationApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { CompanyResponse, CompanyAdminDataSourceResponse } from 'src/types';

export const getCompanyListAll = (config: AxiosRequestConfig = {}) =>
  dataTransformationApi.get<CompanyResponse[]>('/api/v1/companies/all', config);

export const getCompanyById = (companyId: string, config: AxiosRequestConfig = {}) =>
  dataTransformationApi.get<CompanyResponse>(`/api/v1/companies/${companyId}`, config);

export const getCompanyAdminDataSourceList = (companyId: string, config: AxiosRequestConfig = {}) =>
  dataTransformationApi.get<CompanyAdminDataSourceResponse[]>(
    `/api/v1/companies/${companyId}/admin-data-sources`,
    config,
  );
