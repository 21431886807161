import qs from 'qs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, matchPath, PathMatch, generatePath } from 'react-router-dom';
import { RoutePath as rp } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import {
  setBreadcrumbsNames,
  setBreadcrumbsParentPath,
  setBreadcrumbsSearchParams,
  setBreadcrumbsLocationState,
} from 'src/store/slices/breadcrumbsSlice';
import {
  breadcrumbsNamesMapSelector,
  breadcrumbsParentPathMapSelector,
  breadcrumbsSearchParamsMapSelector,
  breadcrumbsLocationStateMapSelector,
} from 'src/store/selectors/breadcrumbsSelector';

import {
  TEMPLATE_NAME_BC,
  SCRIPT_NAME_BC,
  MASTER_DATA_DICTIONARY_NAME_BC,
} from 'src/utils/useSetDynamicScriptBreadcrumbs';

import { Breadcrumb, Breadcrumbs } from '@itm/shared-frontend/lib/components';

type BreadcrumbConfig = {
  name: string | null | undefined;
  parentPath: string | null;
  searchParams?: string | null;
  locationState?: Record<string, any> | null;
};

type ExtendedBreadcrumbsConfig = BreadcrumbConfig & Pick<PathMatch<string>, 'pathname'>;

type BreadcrumbConfigMap = {
  [key: string]: BreadcrumbConfig;
};

type UseSetDynamicBreadcrumbOptionsProps = Readonly<{
  routePath: string | null;
  name?: string | null;
  parentPath?: string | null;
  searchParams?: Record<string, string | null> | null;
  locationState?: Record<string, any> | null;
  isClearOnUnmount?: boolean;
}>;

export const useSetDynamicBreadcrumbOptions = ({
  routePath,
  name,
  parentPath,
  searchParams,
  locationState,
  isClearOnUnmount = true,
}: UseSetDynamicBreadcrumbOptionsProps) => {
  const dispatch = useDispatch();
  const searchParamsString = searchParams ? qs.stringify(searchParams) : null;

  // Set
  useEffect(() => {
    if (!name || !routePath) return;
    dispatch(setBreadcrumbsNames({ [routePath]: name }));
  }, [routePath, name, dispatch]);

  useEffect(() => {
    if (!parentPath || !routePath) return;
    dispatch(setBreadcrumbsParentPath({ [routePath]: parentPath }));
  }, [routePath, parentPath, dispatch]);

  useEffect(() => {
    if (!searchParamsString || !routePath) return;
    dispatch(setBreadcrumbsSearchParams({ [routePath]: searchParamsString }));
  }, [routePath, searchParamsString, dispatch]);

  useEffect(() => {
    if (!locationState || !routePath) return;
    dispatch(setBreadcrumbsLocationState({ [routePath]: locationState }));
  }, [routePath, locationState, dispatch]);

  // Clear
  type Params = Pick<UseSetDynamicBreadcrumbOptionsProps, 'name' | 'parentPath' | 'locationState'> & {
    searchParamsString: string | null;
  };
  const paramsRef = useRef<Params>();
  useEffect(() => {
    paramsRef.current = { name, parentPath, searchParamsString, locationState };
  }, [name, parentPath, searchParamsString, locationState]);

  useEffect(
    () => () => {
      if (!isClearOnUnmount || !routePath || !paramsRef.current) return;
      const { name, parentPath, searchParamsString, locationState } = paramsRef.current;

      if (name) dispatch(setBreadcrumbsNames({ [routePath]: null }));
      if (parentPath) dispatch(setBreadcrumbsParentPath({ [routePath]: null }));
      if (searchParamsString) dispatch(setBreadcrumbsSearchParams({ [routePath]: null }));
      if (locationState) dispatch(setBreadcrumbsLocationState({ [routePath]: null }));
    },

    [routePath, isClearOnUnmount, dispatch],
  );
};

const getBreadcrumbs = (pathToMatch: string, breadcrumbsConfig: BreadcrumbConfigMap): Breadcrumb[] => {
  const matchedList = Object.keys(breadcrumbsConfig).reduce<PathMatch<string>[]>((acc, route) => {
    const matched = matchPath(route, pathToMatch);
    return matched ? [...acc, matched] : acc;
  }, []);

  if (!matchedList.length) return [];

  const pathMatch =
    // try to find the full match
    matchedList.find((m) => !m.pattern.path.endsWith('/*')) ||
    // if not found, find the longest wildcard match
    matchedList.toSorted((a, b) => b.pattern.path.length - a.pattern.path.length)[0];

  const routeParams = pathMatch.params;

  let revertedBreadcrumbsList: Breadcrumb[] = [];
  let extendedConfig: ExtendedBreadcrumbsConfig | null = {
    pathname: pathMatch.pathname,
    ...breadcrumbsConfig[pathMatch.pattern.path],
  };

  while (extendedConfig) {
    const { name, pathname, parentPath, searchParams, locationState }: ExtendedBreadcrumbsConfig = extendedConfig;

    if (name) {
      revertedBreadcrumbsList.push({
        name,
        state: locationState,
        to: { pathname, search: searchParams || undefined },
      });
    } else {
      revertedBreadcrumbsList = [];
    }

    extendedConfig = parentPath
      ? { ...breadcrumbsConfig[parentPath], pathname: generatePath(parentPath, routeParams) }
      : null;
  }

  return revertedBreadcrumbsList.reverse();
};

function BreadcrumbsTrail() {
  const { pathname } = useLocation();
  const bcNamesMap = useSelector(breadcrumbsNamesMapSelector);
  const bcParentPathMap = useSelector(breadcrumbsParentPathMapSelector);
  const bcSearchParamsMap = useSelector(breadcrumbsSearchParamsMapSelector);
  const bcLocationStateMap = useSelector(breadcrumbsLocationStateMapSelector);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const isHidden = useMemo(
    () =>
      Boolean(
        matchPath(rp.root, pathname) ||
          matchPath(rp.loginRedirect, pathname) ||
          matchPath(rp.permissionDenied, pathname),
      ),
    [pathname],
  );

  const config: BreadcrumbConfigMap = useMemo(() => {
    const additionalOptions: BreadcrumbConfigMap = {
      [rp.additionalOptionsRoot]: { name: 'Additional options', parentPath: null },
      [rp.additionalOptionsSqlCredentialsRoot]: {
        name: 'SQL server credentials',
        parentPath: rp.additionalOptionsRoot,
      },
      [rp.additionalOptionsSqlCredentialsUsers]: {
        name: 'User SQL credentials',
        parentPath: rp.additionalOptionsSqlCredentialsRoot,
      },
      [rp.additionalOptionsProductInfoRoot]: { name: 'Product information', parentPath: rp.additionalOptionsRoot },
      [rp.additionalOptionsProductInfoDetails]: {
        name: 'Technical details',
        parentPath: rp.additionalOptionsProductInfoRoot,
      },
    };

    const migration: BreadcrumbConfigMap = {
      [rp.migrationRoot]: { name: 'Migration', parentPath: null },

      // Projects
      [rp.migrationProjectsRoot]: { name: 'Projects', parentPath: rp.migrationRoot },
      [rp.migrationProjectsList]: { name: 'All projects', parentPath: rp.migrationProjectsRoot },
      [`${rp.migrationProjectsCreateRoot}/*`]: { name: 'Create new project', parentPath: rp.migrationProjectsRoot },

      // Projects / Project
      [rp.migrationProjectRoot]: { name: bcNamesMap[rp.migrationProjectRoot], parentPath: rp.migrationProjectsList },

      // Projects / Project / Details
      [rp.migrationProjectDetails]: { name: 'Project Details', parentPath: rp.migrationProjectRoot },

      // Projects / Project / Data Dictionary
      [rp.migrationProjectDataDictionaryRoot]: { name: 'Data Dictionary', parentPath: rp.migrationProjectRoot },
      [rp.migrationProjectDataDictionarySourceData]: {
        name: 'Source data details',
        parentPath: rp.migrationProjectDataDictionaryRoot,
      },
      [`${rp.migrationProjectDataDictionaryDefinitionRoot}/*`]: {
        name: 'Data structure definition',
        parentPath: rp.migrationProjectDataDictionaryRoot,
      },
      [`${rp.migrationProjectDataDictionaryBulkUpdateRoot}/*`]: {
        name: 'Bulk update',
        parentPath: rp.migrationProjectDataDictionaryRoot,
      },

      // Projects / Project / Databases
      [rp.migrationProjectDatabasesRoot]: { name: 'Project Databases', parentPath: rp.migrationProjectRoot },
      [rp.migrationProjectDatabasesCurrent]: { name: 'Current database', parentPath: rp.migrationProjectDatabasesRoot },
      [rp.migrationProjectDatabasesHistoryList]: {
        name: 'Database history',
        parentPath: rp.migrationProjectDatabasesRoot,
      },
      [rp.migrationProjectDatabasesHistoryDetails]: {
        name: 'Summary',
        parentPath: rp.migrationProjectDatabasesHistoryList,
      },
      [rp.migrationProjectDatabasesHistoryDetailsTableDetails]: {
        name: bcNamesMap[rp.migrationProjectDatabasesHistoryDetailsTableDetails],
        parentPath: rp.migrationProjectDatabasesHistoryDetails,
      },
      [rp.migrationProjectDatabasesUpdateSource]: {
        name: bcNamesMap[rp.migrationProjectDatabasesUpdateSource],
        parentPath: rp.migrationProjectDatabasesRoot,
      },
      [rp.migrationProjectDatabasesTables]: {
        name: 'Tables',
        parentPath: rp.migrationProjectDatabasesRoot,
      },

      // Projects / Project / Templates
      [rp.migrationProjectTemplatesRoot]: { name: 'Project Templates', parentPath: rp.migrationProjectRoot },

      // Projects / Project / Templates / Assigned
      [rp.migrationProjectTemplatesList]: { name: 'Assigned templates', parentPath: rp.migrationProjectTemplatesRoot },
      [rp.migrationProjectTemplatesAssign]: { name: 'Add new', parentPath: rp.migrationProjectTemplatesList },
      [rp.migrationProjectTemplatesCreate]: {
        name: 'Create new template',
        parentPath: rp.migrationProjectTemplatesList,
      },
      [rp.migrationProjectTemplatesEditRoot]: {
        name: bcNamesMap[rp.migrationProjectTemplatesEditRoot],
        searchParams: bcSearchParamsMap[rp.migrationProjectTemplatesEditRoot],
        parentPath: rp.migrationProjectTemplatesList,
      },
      [rp.migrationProjectTemplatesEditDetails]: {
        name: 'Template details',
        parentPath: rp.migrationProjectTemplatesEditRoot,
      },
      [rp.migrationProjectTemplatesEditReadOnly]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: rp.migrationProjectTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },
      [rp.migrationProjectTemplatesEditScript]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: bcParentPathMap[TEMPLATE_NAME_BC] || rp.migrationProjectTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },

      [rp.migrationProjectTemplatesScriptCreate]: {
        name: 'Create script',
        parentPath: rp.migrationProjectTemplatesEditScript,
      },

      // Projects / Project / Templates / Assigned / View / Scripts
      [rp.migrationProjectTemplatesScriptViewRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.migrationProjectTemplatesEditReadOnly,
      },
      [rp.migrationProjectTemplatesScriptViewUpdate]: {
        name: 'Edit script',
        parentPath: rp.migrationProjectTemplatesScriptViewRoot,
      },
      [rp.migrationProjectTemplatesScriptViewDetails]: {
        name: 'Script details',
        parentPath: rp.migrationProjectTemplatesScriptViewRoot,
      },
      [rp.migrationProjectTemplatesScriptViewHistory]: {
        name: 'Script history',
        parentPath: rp.migrationProjectTemplatesScriptViewRoot,
      },
      [rp.migrationProjectTemplatesScriptViewLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.migrationProjectTemplatesScriptViewRoot,
      },
      [rp.migrationProjectTemplatesScriptViewVersion]: {
        name: 'Script versions',
        parentPath: rp.migrationProjectTemplatesScriptViewRoot,
      },
      [rp.migrationProjectTemplatesScriptViewInfo]: {
        name: 'Script info',
        parentPath: rp.migrationProjectTemplatesScriptViewRoot,
      },

      // Projects / Project / Templates / Assigned / Edit / Scripts
      [rp.migrationProjectTemplatesScriptEditRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.migrationProjectTemplatesEditScript,
      },
      [rp.migrationProjectTemplatesScriptEditUpdate]: {
        name: 'Edit script',
        parentPath: rp.migrationProjectTemplatesScriptEditRoot,
      },
      [rp.migrationProjectTemplatesScriptEditDetails]: {
        name: 'Script details',
        parentPath: rp.migrationProjectTemplatesScriptEditRoot,
      },
      [rp.migrationProjectTemplatesScriptEditHistory]: {
        name: 'Script history',
        parentPath: rp.migrationProjectTemplatesScriptEditRoot,
      },
      [rp.migrationProjectTemplatesScriptEditLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.migrationProjectTemplatesScriptEditRoot,
      },
      [rp.migrationProjectTemplatesScriptEditVersion]: {
        name: 'Script versions',
        parentPath: rp.migrationProjectTemplatesScriptEditRoot,
      },
      [rp.migrationProjectTemplatesScriptEditInfo]: {
        name: 'Script info',
        parentPath: rp.migrationProjectTemplatesScriptEditRoot,
      },

      // Projects / Project / Templates / Run History
      [rp.migrationProjectTemplatesRunHistoryList]: {
        name: 'Run history',
        parentPath: rp.migrationProjectTemplatesRoot,
      },
      [rp.migrationProjectTemplatesRunHistoryDetails]: {
        name: 'Details',
        parentPath: rp.migrationProjectTemplatesRunHistoryList,
      },

      // Projects / Project / User permissions
      [rp.migrationProjectUsersRoot]: { name: 'User permissions', parentPath: rp.migrationProjectRoot },
      [rp.migrationProjectUsersProjectAccess]: { name: 'Project access', parentPath: rp.migrationProjectUsersRoot },
      [rp.migrationProjectUsersSQLPermissions]: { name: 'SQL permissions', parentPath: rp.migrationProjectUsersRoot },

      // Master Templates
      [rp.masterTemplatesRoot]: { name: 'Master templates', parentPath: rp.migrationRoot },
      [rp.masterTemplatesList]: { name: 'All templates', parentPath: rp.masterTemplatesRoot },
      [rp.masterTemplatesCreate]: { name: 'Create new template', parentPath: rp.masterTemplatesRoot },
      [rp.masterTemplatesEditRoot]: {
        name: bcNamesMap[rp.masterTemplatesEditRoot],
        searchParams: bcSearchParamsMap[rp.masterTemplatesEditRoot],
        parentPath: rp.masterTemplatesList,
      },
      [rp.masterTemplatesEditDetails]: {
        name: 'Template details',
        parentPath: rp.masterTemplatesEditRoot,
      },
      [rp.masterTemplatesEditReadOnly]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: rp.masterTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },
      [rp.masterTemplatesEditScript]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: bcParentPathMap[TEMPLATE_NAME_BC] || rp.masterTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },

      [rp.masterTemplatesScriptCreate]: {
        name: 'Create script',
        parentPath: rp.masterTemplatesEditScript,
      },

      // Master Templates / View / Scripts
      [rp.masterTemplatesScriptViewRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.masterTemplatesEditReadOnly,
      },
      [rp.masterTemplatesScriptViewUpdate]: {
        name: 'Edit script',
        parentPath: rp.masterTemplatesScriptViewRoot,
      },
      [rp.masterTemplatesScriptViewDetails]: {
        name: 'Script details',
        parentPath: rp.masterTemplatesScriptViewRoot,
      },
      [rp.masterTemplatesScriptViewHistory]: {
        name: 'Script history',
        parentPath: rp.masterTemplatesScriptViewRoot,
      },
      [rp.masterTemplatesScriptViewLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.masterTemplatesScriptViewRoot,
      },
      [rp.masterTemplatesScriptViewVersion]: {
        name: 'Script versions',
        parentPath: rp.masterTemplatesScriptViewRoot,
      },
      [rp.masterTemplatesScriptViewInfo]: {
        name: 'Script info',
        parentPath: rp.masterTemplatesScriptViewRoot,
      },

      // Master Templates / Edit / Scripts
      [rp.masterTemplatesScriptEditRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.masterTemplatesEditScript,
      },
      [rp.masterTemplatesScriptEditUpdate]: {
        name: 'Edit script',
        parentPath: rp.masterTemplatesScriptEditRoot,
      },
      [rp.masterTemplatesScriptEditDetails]: {
        name: 'Script details',
        parentPath: rp.masterTemplatesScriptEditRoot,
      },
      [rp.masterTemplatesScriptEditHistory]: {
        name: 'Script history',
        parentPath: rp.masterTemplatesScriptEditRoot,
      },
      [rp.masterTemplatesScriptEditLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.masterTemplatesScriptEditRoot,
      },
      [rp.masterTemplatesScriptEditVersion]: {
        name: 'Script versions',
        parentPath: rp.masterTemplatesScriptEditRoot,
      },
      [rp.masterTemplatesScriptEditInfo]: {
        name: 'Script info',
        parentPath: rp.masterTemplatesScriptEditRoot,
      },

      // Master Data Dictionary
      [rp.masterDataDictionaryRoot]: { name: 'Master data dictionary', parentPath: rp.migrationRoot },
      [rp.masterDataDictionaryList]: { name: 'All master dictionaries', parentPath: rp.masterDataDictionaryRoot },
      [`${rp.masterDataDictionaryCreateRoot}/*`]: {
        name: 'Create new dictionary',
        parentPath: rp.masterDataDictionaryRoot,
      },

      // Master Data Dictionary / View
      [rp.masterDataDictionaryViewRoot]: {
        name: bcNamesMap[MASTER_DATA_DICTIONARY_NAME_BC],
        parentPath: rp.masterDataDictionaryList,
      },
      [rp.masterDataDictionaryViewApplication]: {
        name: 'Source application',
        parentPath: rp.masterDataDictionaryViewRoot,
      },
      [`${rp.masterDataDictionaryViewDataDefinitionRoot}/*`]: {
        name: 'Data definition',
        parentPath: rp.masterDataDictionaryViewRoot,
      },

      // Master Data Dictionary / Edit
      [rp.masterDataDictionaryEditRoot]: {
        name: bcNamesMap[MASTER_DATA_DICTIONARY_NAME_BC],
        parentPath: rp.masterDataDictionaryList,
      },
      [rp.masterDataDictionaryEditApplication]: {
        name: 'Source application',
        parentPath: rp.masterDataDictionaryEditRoot,
      },
      [`${rp.masterDataDictionaryEditDataDefinitionRoot}/*`]: {
        name: 'Data definition',
        parentPath: rp.masterDataDictionaryEditRoot,
      },
      [`${rp.masterDataDictionaryEditBulkUpdateRoot}/*`]: {
        name: 'Bulk update',
        parentPath: rp.masterDataDictionaryEditRoot,
      },

      // Applications
      [rp.applicationRoot]: { name: 'Applications', parentPath: rp.migrationRoot },
      [rp.applicationList]: { name: 'Supported applications', parentPath: rp.applicationRoot },
      [rp.applicationCreate]: { name: 'Create new application', parentPath: rp.applicationRoot },
    };

    const translations: BreadcrumbConfigMap = {
      [rp.translationsRoot]: { name: 'Fusion PSI Configuration', parentPath: null },

      // Mappings
      [rp.translationsMappingsRoot]: { name: 'Mappings', parentPath: rp.translationsRoot },
      [rp.translationsMappingsList]: { name: 'All mappings', parentPath: rp.translationsMappingsRoot },
      [`${rp.translationsMappingsCreateRoot}/*`]: {
        name: 'Create new mapping',
        parentPath: rp.translationsMappingsRoot,
      },

      // Mappings / Mapping
      [rp.translationsMappingProjectRoot]: {
        name: bcNamesMap[rp.translationsMappingProjectRoot],
        parentPath: rp.translationsMappingsList,
      },
      [rp.translationsMappingProjectDetails]: {
        name: 'Mapping details',
        parentPath: rp.translationsMappingProjectRoot,
      },

      // Mappings / Mapping / File Structure
      [rp.translationsMappingProjectFileStructure]: {
        name: 'File structure List',
        parentPath: rp.translationsMappingProjectRoot,
      },
      [rp.translationsMappingProjectFileStructureTable]: {
        name: bcNamesMap[rp.translationsMappingProjectFileStructureTable],
        parentPath: rp.translationsMappingProjectFileStructure,
      },
      [rp.translationsMappingProjectFileStructureColumn]: {
        name: bcNamesMap[rp.translationsMappingProjectFileStructureColumn],
        parentPath: rp.translationsMappingProjectFileStructure,
      },

      // Mappings / Mapping / Templates
      [rp.translationsMappingProjectTemplatesRoot]: {
        name: 'Mapping Templates',
        parentPath: rp.translationsMappingProjectRoot,
      },
      [rp.translationsMappingProjectTemplatesList]: {
        name: 'Assigned templates',
        parentPath: rp.translationsMappingProjectTemplatesRoot,
      },
      [rp.translationsMappingProjectTemplatesAssign]: {
        name: 'Add new template',
        parentPath: rp.translationsMappingProjectTemplatesList,
      },
      [rp.translationsMappingProjectTemplatesCreate]: {
        name: 'Create new template',
        parentPath: rp.translationsMappingProjectTemplatesList,
      },
      [rp.translationsMappingProjectTemplatesEditRoot]: {
        name: bcNamesMap[rp.translationsMappingProjectTemplatesEditRoot],
        searchParams: bcSearchParamsMap[rp.translationsMappingProjectTemplatesEditRoot],
        parentPath: rp.translationsMappingProjectTemplatesList,
      },
      [rp.translationsMappingProjectTemplatesEditDetails]: {
        name: 'Template details',
        parentPath: rp.translationsMappingProjectTemplatesEditRoot,
      },
      [rp.translationsMappingProjectTemplatesEditReadOnly]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: rp.translationsMappingProjectTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },
      [rp.translationsMappingProjectTemplatesEditScript]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: bcParentPathMap[TEMPLATE_NAME_BC] || rp.translationsMappingProjectTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },

      [rp.translationsMappingProjectTemplatesScriptCreate]: {
        name: 'Create script',
        parentPath: rp.translationsMappingProjectTemplatesEditScript,
      },

      // Mappings / Mapping / Templates / View / Scripts
      [rp.translationsMappingProjectTemplatesScriptViewRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.translationsMappingProjectTemplatesEditReadOnly,
      },
      [rp.translationsMappingProjectTemplatesScriptViewUpdate]: {
        name: 'Edit script',
        parentPath: rp.translationsMappingProjectTemplatesScriptViewRoot,
      },
      [rp.translationsMappingProjectTemplatesScriptViewDetails]: {
        name: 'Script details',
        parentPath: rp.translationsMappingProjectTemplatesScriptViewRoot,
      },
      [rp.translationsMappingProjectTemplatesScriptViewHistory]: {
        name: 'Script history',
        parentPath: rp.translationsMappingProjectTemplatesScriptViewRoot,
      },
      [rp.translationsMappingProjectTemplatesScriptViewLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.translationsMappingProjectTemplatesScriptViewRoot,
      },
      [rp.translationsMappingProjectTemplatesScriptViewVersion]: {
        name: 'Script versions',
        parentPath: rp.translationsMappingProjectTemplatesScriptViewRoot,
      },
      [rp.translationsMappingProjectTemplatesScriptViewInfo]: {
        name: 'Script info',
        parentPath: rp.translationsMappingProjectTemplatesScriptViewRoot,
      },

      // Mappings / Mapping / Templates / Edit / Scripts
      [rp.translationsMappingProjectTemplatesScriptEditRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.translationsMappingProjectTemplatesEditScript,
      },
      [rp.translationsMappingProjectTemplatesScriptEditUpdate]: {
        name: 'Edit script',
        parentPath: rp.translationsMappingProjectTemplatesScriptEditRoot,
      },
      [rp.translationsMappingProjectTemplatesScriptEditDetails]: {
        name: 'Script details',
        parentPath: rp.translationsMappingProjectTemplatesScriptEditRoot,
      },
      [rp.translationsMappingProjectTemplatesScriptEditHistory]: {
        name: 'Script history',
        parentPath: rp.translationsMappingProjectTemplatesScriptEditRoot,
      },
      [rp.translationsMappingProjectTemplatesScriptEditLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.translationsMappingProjectTemplatesScriptEditRoot,
      },
      [rp.translationsMappingProjectTemplatesScriptEditVersion]: {
        name: 'Script versions',
        parentPath: rp.translationsMappingProjectTemplatesScriptEditRoot,
      },
      [rp.translationsMappingProjectTemplatesScriptEditInfo]: {
        name: 'Script info',
        parentPath: rp.translationsMappingProjectTemplatesScriptEditRoot,
      },

      // Master Templates
      [rp.translationsMasterTemplatesRoot]: { name: 'Master templates', parentPath: rp.translationsRoot },
      [rp.translationsMasterTemplatesList]: { name: 'All templates', parentPath: rp.translationsMasterTemplatesRoot },
      [rp.translationsMasterTemplatesCreate]: {
        name: 'Create new template',
        parentPath: rp.translationsMasterTemplatesRoot,
      },
      [rp.translationsMasterTemplatesEditRoot]: {
        name: bcNamesMap[rp.translationsMasterTemplatesEditRoot],
        searchParams: bcSearchParamsMap[rp.translationsMasterTemplatesEditRoot],
        parentPath: rp.translationsMasterTemplatesList,
      },
      [rp.translationsMasterTemplatesEditDetails]: {
        name: 'Template details',
        parentPath: rp.translationsMasterTemplatesEditRoot,
      },
      [rp.translationsMasterTemplatesEditReadOnly]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: rp.translationsMasterTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },
      [rp.translationsMasterTemplatesEditScript]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: bcParentPathMap[TEMPLATE_NAME_BC] || rp.translationsMasterTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },

      [rp.translationsMasterTemplatesScriptCreate]: {
        name: 'Create script',
        parentPath: rp.translationsMasterTemplatesEditScript,
      },

      // Master Templates / View / Scripts
      [rp.translationsMasterTemplatesScriptViewRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.translationsMasterTemplatesEditReadOnly,
      },
      [rp.translationsMasterTemplatesScriptViewUpdate]: {
        name: 'Edit script',
        parentPath: rp.translationsMasterTemplatesScriptViewRoot,
      },
      [rp.translationsMasterTemplatesScriptViewDetails]: {
        name: 'Script details',
        parentPath: rp.translationsMasterTemplatesScriptViewRoot,
      },
      [rp.translationsMasterTemplatesScriptViewHistory]: {
        name: 'Script history',
        parentPath: rp.translationsMasterTemplatesScriptViewRoot,
      },
      [rp.translationsMasterTemplatesScriptViewLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.translationsMasterTemplatesScriptViewRoot,
      },
      [rp.translationsMasterTemplatesScriptViewVersion]: {
        name: 'Script versions',
        parentPath: rp.translationsMasterTemplatesScriptViewRoot,
      },
      [rp.translationsMasterTemplatesScriptViewInfo]: {
        name: 'Script info',
        parentPath: rp.translationsMasterTemplatesScriptViewRoot,
      },

      // Master Templates / Edit / Scripts
      [rp.translationsMasterTemplatesScriptEditRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.translationsMasterTemplatesEditScript,
      },
      [rp.translationsMasterTemplatesScriptEditUpdate]: {
        name: 'Edit script',
        parentPath: rp.translationsMasterTemplatesScriptEditRoot,
      },
      [rp.translationsMasterTemplatesScriptEditDetails]: {
        name: 'Script details',
        parentPath: rp.translationsMasterTemplatesScriptEditRoot,
      },
      [rp.translationsMasterTemplatesScriptEditHistory]: {
        name: 'Script history',
        parentPath: rp.translationsMasterTemplatesScriptEditRoot,
      },
      [rp.translationsMasterTemplatesScriptEditLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.translationsMasterTemplatesScriptEditRoot,
      },
      [rp.translationsMasterTemplatesScriptEditVersion]: {
        name: 'Script versions',
        parentPath: rp.translationsMasterTemplatesScriptEditRoot,
      },
      [rp.translationsMasterTemplatesScriptEditInfo]: {
        name: 'Script info',
        parentPath: rp.translationsMasterTemplatesScriptEditRoot,
      },
    };

    const onboarding: BreadcrumbConfigMap = {
      [rp.onboardingRoot]: { name: 'Data Onboarding', parentPath: null },

      // Configurations
      [rp.onboardingConfigurationsRoot]: { name: 'Configurations', parentPath: rp.onboardingRoot },
      [rp.onboardingConfigurationsList]: { name: 'All configurations', parentPath: rp.onboardingConfigurationsRoot },
      [`${rp.onboardingConfigurationCreateRoot}/*`]: {
        name: 'Create new configuration',
        parentPath: rp.onboardingConfigurationsRoot,
      },

      // Configurations / Configuration
      [rp.onboardingConfigurationRoot]: {
        name: bcNamesMap[rp.onboardingConfigurationRoot],
        parentPath: rp.onboardingConfigurationsList,
      },

      // Configurations / Configuration / Details
      [rp.onboardingConfigurationDetails]: { name: 'Client Details', parentPath: rp.onboardingConfigurationRoot },

      // Configurations / Configuration / Data Dictionary
      [rp.onboardingConfigurationDataDictionaryRoot]: {
        name: 'Data Dictionary',
        parentPath: rp.onboardingConfigurationRoot,
      },
      [rp.onboardingConfigurationDataDictionarySourceData]: {
        name: 'Source data details',
        parentPath: rp.onboardingConfigurationDataDictionaryRoot,
      },
      [`${rp.onboardingConfigurationDataDictionaryDefinitionRoot}/*`]: {
        name: 'Data structure definition',
        parentPath: rp.onboardingConfigurationDataDictionaryRoot,
      },
      [`${rp.onboardingConfigurationDataDictionaryBulkUpdateRoot}/*`]: {
        name: 'Bulk update',
        parentPath: rp.onboardingConfigurationDataDictionaryRoot,
      },

      // Configurations / Configuration / Databases
      [rp.onboardingConfigurationDatabasesRoot]: {
        name: 'Configuration Databases',
        parentPath: rp.onboardingConfigurationRoot,
      },
      [rp.onboardingConfigurationDatabasesCurrent]: {
        name: 'Current database',
        parentPath: rp.onboardingConfigurationDatabasesRoot,
      },
      [rp.onboardingConfigurationDatabasesHistoryList]: {
        name: 'Database history',
        parentPath: rp.onboardingConfigurationDatabasesRoot,
      },
      [rp.onboardingConfigurationDatabasesHistoryDetails]: {
        name: 'Summary',
        parentPath: rp.onboardingConfigurationDatabasesHistoryList,
      },
      [rp.onboardingConfigurationDatabasesHistoryDetailsTableDetails]: {
        name: bcNamesMap[rp.onboardingConfigurationDatabasesHistoryDetailsTableDetails],
        parentPath: rp.onboardingConfigurationDatabasesHistoryDetails,
      },
      [rp.onboardingConfigurationDatabasesUpdateSource]: {
        name: bcNamesMap[rp.onboardingConfigurationDatabasesUpdateSource],
        parentPath: rp.onboardingConfigurationDatabasesRoot,
      },
      [rp.onboardingConfigurationDatabasesCompareSource]: {
        name: 'Compare data source',
        parentPath: rp.onboardingConfigurationDatabasesRoot,
      },

      // Configurations / Configuration / Templates
      [rp.onboardingConfigurationTemplatesRoot]: {
        name: 'Mapping Templates',
        parentPath: rp.onboardingConfigurationRoot,
      },

      // Configurations / Configuration / Templates / Assigned
      [rp.onboardingConfigurationTemplatesList]: {
        name: 'Assigned templates',
        parentPath: rp.onboardingConfigurationTemplatesRoot,
      },
      [rp.onboardingConfigurationTemplatesAssign]: {
        name: 'Add new',
        parentPath: rp.onboardingConfigurationTemplatesList,
      },
      [rp.onboardingConfigurationTemplatesCreate]: {
        name: 'Create new template',
        parentPath: rp.onboardingConfigurationTemplatesList,
      },
      [rp.onboardingConfigurationTemplatesEditRoot]: {
        name: bcNamesMap[rp.onboardingConfigurationTemplatesEditRoot],
        searchParams: bcSearchParamsMap[rp.onboardingConfigurationTemplatesEditRoot],
        parentPath: rp.onboardingConfigurationTemplatesList,
      },
      [rp.onboardingConfigurationTemplatesEditDetails]: {
        name: 'Template details',
        parentPath: rp.onboardingConfigurationTemplatesEditRoot,
      },
      [rp.onboardingConfigurationTemplatesEditReadOnly]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: rp.onboardingConfigurationTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },
      [rp.onboardingConfigurationTemplatesEditScript]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: bcParentPathMap[TEMPLATE_NAME_BC] || rp.onboardingConfigurationTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },

      [rp.onboardingConfigurationTemplatesScriptCreate]: {
        name: 'Create script',
        parentPath: rp.onboardingConfigurationTemplatesEditScript,
      },

      // Configurations / Configuration / Templates / Assigned / View / Scripts
      [rp.onboardingConfigurationTemplatesScriptViewRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.onboardingConfigurationTemplatesEditReadOnly,
      },
      [rp.onboardingConfigurationTemplatesScriptViewUpdate]: {
        name: 'Edit script',
        parentPath: rp.onboardingConfigurationTemplatesScriptViewRoot,
      },
      [rp.onboardingConfigurationTemplatesScriptViewDetails]: {
        name: 'Script details',
        parentPath: rp.onboardingConfigurationTemplatesScriptViewRoot,
      },
      [rp.onboardingConfigurationTemplatesScriptViewHistory]: {
        name: 'Script history',
        parentPath: rp.onboardingConfigurationTemplatesScriptViewRoot,
      },
      [rp.onboardingConfigurationTemplatesScriptViewLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.onboardingConfigurationTemplatesScriptViewRoot,
      },
      [rp.onboardingConfigurationTemplatesScriptViewVersion]: {
        name: 'Script versions',
        parentPath: rp.onboardingConfigurationTemplatesScriptViewRoot,
      },
      [rp.onboardingConfigurationTemplatesScriptViewInfo]: {
        name: 'Script info',
        parentPath: rp.onboardingConfigurationTemplatesScriptViewRoot,
      },

      // Configurations / Configuration / Templates / Assigned / Edit / Scripts
      [rp.onboardingConfigurationTemplatesScriptEditRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.onboardingConfigurationTemplatesEditScript,
      },
      [rp.onboardingConfigurationTemplatesScriptEditUpdate]: {
        name: 'Edit script',
        parentPath: rp.onboardingConfigurationTemplatesScriptEditRoot,
      },
      [rp.onboardingConfigurationTemplatesScriptEditDetails]: {
        name: 'Script details',
        parentPath: rp.onboardingConfigurationTemplatesScriptEditRoot,
      },
      [rp.onboardingConfigurationTemplatesScriptEditHistory]: {
        name: 'Script history',
        parentPath: rp.onboardingConfigurationTemplatesScriptEditRoot,
      },
      [rp.onboardingConfigurationTemplatesScriptEditLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.onboardingConfigurationTemplatesScriptEditRoot,
      },
      [rp.onboardingConfigurationTemplatesScriptEditVersion]: {
        name: 'Script versions',
        parentPath: rp.onboardingConfigurationTemplatesScriptEditRoot,
      },
      [rp.onboardingConfigurationTemplatesScriptEditInfo]: {
        name: 'Script info',
        parentPath: rp.onboardingConfigurationTemplatesScriptEditRoot,
      },

      // Configurations / Configuration / Templates / Run History
      [rp.onboardingConfigurationTemplatesRunHistoryList]: {
        name: 'Run history',
        parentPath: rp.onboardingConfigurationTemplatesRoot,
      },
      [rp.onboardingConfigurationTemplatesRunHistoryDetails]: {
        name: 'Details',
        parentPath: rp.onboardingConfigurationTemplatesRunHistoryList,
      },

      // Configurations / Configuration / User permissions
      [rp.onboardingConfigurationUsersRoot]: { name: 'User permissions', parentPath: rp.onboardingConfigurationRoot },
      [rp.onboardingConfigurationUsersProjectAccess]: {
        name: 'Configuration access',
        parentPath: rp.onboardingConfigurationUsersRoot,
      },
      [rp.onboardingConfigurationUsersSQLPermissions]: {
        name: 'SQL permissions',
        parentPath: rp.onboardingConfigurationUsersRoot,
      },

      // Master Templates
      [rp.onboardingMasterTemplatesRoot]: { name: 'Master templates', parentPath: rp.onboardingRoot },
      [rp.onboardingMasterTemplatesList]: { name: 'All templates', parentPath: rp.onboardingMasterTemplatesRoot },
      [rp.onboardingMasterTemplatesCreate]: {
        name: 'Create new template',
        parentPath: rp.onboardingMasterTemplatesRoot,
      },
      [rp.onboardingMasterTemplatesEditRoot]: {
        name: bcNamesMap[rp.onboardingMasterTemplatesEditRoot],
        searchParams: bcSearchParamsMap[rp.onboardingMasterTemplatesEditRoot],
        parentPath: rp.onboardingMasterTemplatesList,
      },
      [rp.onboardingMasterTemplatesEditDetails]: {
        name: 'Template details',
        parentPath: rp.onboardingMasterTemplatesEditRoot,
      },
      [rp.onboardingMasterTemplatesEditReadOnly]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: rp.onboardingMasterTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },
      [rp.onboardingMasterTemplatesEditScript]: {
        name: bcNamesMap[TEMPLATE_NAME_BC],
        parentPath: bcParentPathMap[TEMPLATE_NAME_BC] || rp.onboardingMasterTemplatesList,
        locationState: bcLocationStateMap[TEMPLATE_NAME_BC],
      },

      [rp.onboardingMasterTemplatesScriptCreate]: {
        name: 'Create script',
        parentPath: rp.onboardingMasterTemplatesEditScript,
      },

      // Master Templates / View / Scripts
      [rp.onboardingMasterTemplatesScriptViewRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.onboardingMasterTemplatesEditReadOnly,
      },
      [rp.onboardingMasterTemplatesScriptViewUpdate]: {
        name: 'Edit script',
        parentPath: rp.onboardingMasterTemplatesScriptViewRoot,
      },
      [rp.onboardingMasterTemplatesScriptViewDetails]: {
        name: 'Script details',
        parentPath: rp.onboardingMasterTemplatesScriptViewRoot,
      },
      [rp.onboardingMasterTemplatesScriptViewHistory]: {
        name: 'Script history',
        parentPath: rp.onboardingMasterTemplatesScriptViewRoot,
      },
      [rp.onboardingMasterTemplatesScriptViewLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.onboardingMasterTemplatesScriptViewRoot,
      },
      [rp.onboardingMasterTemplatesScriptViewVersion]: {
        name: 'Script versions',
        parentPath: rp.onboardingMasterTemplatesScriptViewRoot,
      },
      [rp.onboardingMasterTemplatesScriptViewInfo]: {
        name: 'Script info',
        parentPath: rp.onboardingMasterTemplatesScriptViewRoot,
      },

      // Master Templates / Edit / Scripts
      [rp.onboardingMasterTemplatesScriptEditRoot]: {
        name: bcNamesMap[SCRIPT_NAME_BC],
        parentPath: rp.onboardingMasterTemplatesEditScript,
      },
      [rp.onboardingMasterTemplatesScriptEditUpdate]: {
        name: 'Edit script',
        parentPath: rp.onboardingMasterTemplatesScriptEditRoot,
      },
      [rp.onboardingMasterTemplatesScriptEditDetails]: {
        name: 'Script details',
        parentPath: rp.onboardingMasterTemplatesScriptEditRoot,
      },
      [rp.onboardingMasterTemplatesScriptEditHistory]: {
        name: 'Script history',
        parentPath: rp.onboardingMasterTemplatesScriptEditRoot,
      },
      [rp.onboardingMasterTemplatesScriptEditLockAudit]: {
        name: 'Script edit lock audit',
        parentPath: rp.onboardingMasterTemplatesScriptEditRoot,
      },
      [rp.onboardingMasterTemplatesScriptEditVersion]: {
        name: 'Script versions',
        parentPath: rp.onboardingMasterTemplatesScriptEditRoot,
      },
      [rp.onboardingMasterTemplatesScriptEditInfo]: {
        name: 'Script info',
        parentPath: rp.onboardingMasterTemplatesScriptEditRoot,
      },

      // Master Data Dictionary
      [rp.onboardingMasterDataDictionaryRoot]: { name: 'Master data dictionary', parentPath: rp.onboardingRoot },
      [rp.onboardingMasterDataDictionaryList]: {
        name: 'All master dictionaries',
        parentPath: rp.onboardingMasterDataDictionaryRoot,
      },
      [`${rp.onboardingMasterDataDictionaryCreateRoot}/*`]: {
        name: 'Create new dictionary',
        parentPath: rp.onboardingMasterDataDictionaryRoot,
      },

      // Master Data Dictionary / View
      [rp.onboardingMasterDataDictionaryViewRoot]: {
        name: bcNamesMap[MASTER_DATA_DICTIONARY_NAME_BC],
        parentPath: rp.onboardingMasterDataDictionaryList,
      },
      [rp.onboardingMasterDataDictionaryViewApplication]: {
        name: 'Source application',
        parentPath: rp.onboardingMasterDataDictionaryViewRoot,
      },
      [`${rp.onboardingMasterDataDictionaryViewDataDefinitionRoot}/*`]: {
        name: 'Data definition',
        parentPath: rp.onboardingMasterDataDictionaryViewRoot,
      },

      // Master Data Dictionary / Edit
      [rp.onboardingMasterDataDictionaryEditRoot]: {
        name: bcNamesMap[MASTER_DATA_DICTIONARY_NAME_BC],
        parentPath: rp.onboardingMasterDataDictionaryList,
      },
      [rp.onboardingMasterDataDictionaryEditApplication]: {
        name: 'Source application',
        parentPath: rp.onboardingMasterDataDictionaryEditRoot,
      },
      [`${rp.onboardingMasterDataDictionaryEditDataDefinitionRoot}/*`]: {
        name: 'Data definition',
        parentPath: rp.onboardingMasterDataDictionaryEditRoot,
      },
      [`${rp.onboardingMasterDataDictionaryEditBulkUpdateRoot}/*`]: {
        name: 'Bulk update',
        parentPath: rp.onboardingMasterDataDictionaryEditRoot,
      },
    };

    return { ...additionalOptions, ...migration, ...translations, ...onboarding };
  }, [bcNamesMap, bcLocationStateMap, bcParentPathMap, bcSearchParamsMap]);

  // Debounce breadcrumbs update to avoid unnecessary updates by redirects
  useEffect(() => {
    if (isHidden) return;

    const delayTimeout = setTimeout(() => {
      setBreadcrumbs(getBreadcrumbs(pathname, config));
    }, 50);
    return () => {
      clearTimeout(delayTimeout);
    };
  }, [config, isHidden, pathname]);

  return isHidden ? null : <Breadcrumbs breadcrumbs={breadcrumbs} />;
}

export default BreadcrumbsTrail;
