import { GlobalRole } from '@itm/shared-frontend';

export { HttpStatusCode } from 'axios';
export { GlobalRole };
export type {
  SelectOption,
  ServerError,
  ServerFormErrors,
  ExternalFilters,
  TanstackTableChangeParams,
} from '@itm/shared-frontend/lib/types';

export enum TargetPlatform {
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum SearchOrder {
  asc,
  desc,
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'DataTransformation_Analyst',
  Viewer = 'DataTransformation_Viewer',
  ClientAdmin = 'DataTransformation_ClientAdmin',
  Manager = 'DataTransformation_Manager',
}

export enum ModuleType {
  Migration = 'Migration',
  Translation = 'Translation',
  Onboarding = 'Onboarding',
}

export enum ProjectStatus {
  None = 'None',
  Active = 'Active',
  Inactive = 'Inactive',
  Completed = 'Completed',
  DatabaseCreation = 'DatabaseCreation',
  SetupUploadTypeIncomplete = 'SetupUploadTypeIncomplete',
  SetupRestoreDatabaseIncomplete = 'SetupRestoreDatabaseIncomplete',
  SetupAssignTemplatesIncomplete = 'SetupAssignTemplatesIncomplete',
  SetupAddColumnsIncomplete = 'SetupAddColumnsIncomplete',
  SetupDataDictionaryIncomplete = 'SetupDataDictionaryIncomplete',
  DatabaseCreationFailed = 'DatabaseCreationFailed',
}

export enum TemplateStatus {
  Available = 'Available',
  Archived = 'Archived',
  Draft = 'Draft',
}

export enum SQLConfigurationOptions {
  Default = 'Default',
}

export type CompanyResponse = {
  id: string;
  name: string;
};

export type SchemeResponse = {
  id: string;
  name: string | null;
  companyId: string;
};

export type ProductInfoResponse = {
  id: string;
  description: string | null;
  version: string | null;
};

export type CreateProjectDto = {
  name: string;
  schemeId: string;
  projectCode: string;
  sourceApplicationId: string;
  destinationApplicationId: string;
  startDate?: string;
  endDate?: string;
};

export type UpdateProjectDto = {
  projectCode: string;
  status?: ProjectStatus;
  startDate?: string;
  endDate?: string;
  isEmailsEnabled: boolean;
};

export enum LockReason {
  Restore = 'Restore',
  Backup = 'Backup',
  DataComparison = 'DataComparison',
  ProjectRun = 'ProjectRun',
  FlatFileCreation = 'FlatFileCreation',
  DatabaseCreation = 'DatabaseCreation',
}

type FlatFileParametersResponse = {
  dateTimeFormat: string | null;
  delimiter: string | null;
  extension: string | null;
  isHeaderIncluded: boolean;
};

export type ProjectResponse = {
  id: string;
  name: string | null;
  sourceDatabaseName: string | null;
  destinationDatabaseName: string | null;
  workingDatabaseName: string | null;
  projectCode: string | null;
  status: ProjectStatus;
  startDate: string | null;
  endDate: string | null;
  currentBackupName: string | null;
  isLocked: boolean;
  lockReason: string | null;
  sourceApplication: ApplicationDto;
  destinationApplication: ApplicationDto;
  flatFileParameters: FlatFileParametersResponse | null;
  isEmailsEnabled: boolean;
};

export type ProjectDetailsResponse = ProjectResponse & {
  createdByUserId: string;
  schemeName: string | null;
};

export type TemplateRunModel = {
  id: string;
  scripts: string[] | null;
};

export type ProjectRunModel = {
  templates: TemplateRunModel[] | null;
  isRestoreDbRequired: boolean;
};

export type ProjectResponsePagedResponse = {
  totalCount: number;
  items: ProjectResponse[];
};

export type ProjectTypeResponse = {
  id: string;
  name: string;
};

export enum RdsTaskType {
  Backup = 'Backup',
  Restore = 'Restore',
  DataComparison = 'DataComparison',
}

export enum AWSLifecycleStatus {
  None = 'None',
  CompletedWithFailures = 'CompletedWithFailures',
  CompletedWithWarning = 'CompletedWithWarning',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum DatabaseRestoreType {
  Source = 'Source',
  Destination = 'Destination',
}

export enum TemplateType {
  Migration = 'Migration',
  Mapping = 'Mapping',
  Audit = 'Audit',
}

export type DbTaskResultDto = {
  databaseHistoryId: string | null;
  processType: ProcessType;
  uploadType: ProjectUploadType;
  status: AWSLifecycleStatus;
  taskInfo: string | null;
};

export type RestoreModel = {
  projectId: string;
  s3Filename: string | null;
  isComparisonEnabled: boolean;
  databaseRestoreType: DatabaseRestoreType;
};

export type FileResponse = {
  fileName: string;
};

export type CreateTemplateDto = {
  projectId?: string;
  name: string;
  sourceApplicationId: string;
  destinationApplicationId: string;
};

export type CreateDraftTemplateModel = {
  templateId: string;
  projectId?: string;
};

export enum AuditTemplateType {
  Core = 'Core',
  Mapping = 'Mapping',
}

export type TemplateResponse = {
  id: string;
  name: string;
  status: TemplateStatus;
  type: TemplateType;
  auditType: AuditTemplateType | null;
  version: number;
  createdDate: string;
  updatedDate: string | null;
  sourceApplication: ApplicationDto | null;
  destinationApplication: ApplicationDto | null;
  adminDataSource: AdminDataSourceResponse | null;
  canEditTemplate: boolean;
};

export type UpdateTemplateDto = {
  name: string;
  version: number;
  status: TemplateStatus;
  sourceApplicationId: string;
  destinationApplicationId: string;
};

export type UpdateTemplateToMasterDto = {
  name: string;
  withOverride?: boolean;
};

export type TemplateResponsePagedResponse = {
  totalCount: number;
  items: TemplateResponse[];
};

export type CreateMappingTemplateDto = {
  projectId?: string;
  name: string;
};

export type UpdateMappingTemplateDto = {
  name: string;
  version: number;
  status: TemplateStatus;
  projectId?: string;
};

export type ProjectAccessPermissionResponse = {
  canRead: boolean;
  canWrite: boolean;
};

export type ProjectTemplateResponse = TemplateResponse & {
  executionOrder: number;
  assignmentDate: string;
  projectTemplateId: string;
};

export enum RunStatus {
  Started = 'Started',
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
}

export type ProjectRunResponse = {
  id: string;
  runNumber: number;
  runDate: string;
  runByUserName: string;
  errorMessage: string | null;
  status: RunStatus;
};

export type ProjectRunResponsePagedResponse = {
  totalCount: number;
  items: ProjectRunResponse[];
};

export type ProjectTemplateResponsePagedResponse = {
  totalCount: number;
  items: ProjectTemplateResponse[];
};

export type TemplateRunResponse = {
  id: string;
  name: string;
  executionOrder: number;
  runDate: string;
  errorMessage: string | null;
  status: RunStatus;
  levelRuns: LevelRunResponse[];
};

export type LevelRunResponse = {
  id: string;
  name: string;
  position: number;
  runDate: string;
  errorMessage: string;
  status: RunStatus;
  subLevelRuns: LevelRunResponse[];
  scriptRuns: ScriptRunResponse[];
};

export type ScriptRunResponse = {
  id: string;
  name: string;
  position: number;
  query: string;
  runDate: string;
  errorMessage: string;
  status: RunStatus;
};

export type ProjectRunDetailResponse = {
  id: string;
  runNumber: number;
  runDate: string;
  runByUserName: string;
  errorMessage: string | null;
  status: RunStatus;
  templateRuns: TemplateRunResponse[];
};

export type AssignProjectToTemplateModel = {
  templateId: string;
  projectId: string;
  newTemplateName?: string | null;
};

export type ProjectTemplateOrderModel = {
  templateId: string;
  executionOrder: number;
};

export type ProductUserResponse = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  hasSFTPAccess: boolean;
  canChangeAccess: boolean;
};

export type ProductUserResponsePagedResponse = {
  totalCount: number;
  items: ProductUserResponse[];
};

export type ProjectUserResponse = ProductUserResponse & {
  canWrite: boolean;
};

export type ProjectUserResponsePagedResponse = {
  totalCount: number;
  items: ProjectUserResponse[];
};

export type UserResponse = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  sqlLogin: string | null;
  hasLogin: boolean;
};

export type UserResponsePagedResponse = {
  totalCount: number;
  items: UserResponse[];
};

export type ProjectUserModel = {
  userId: string;
  canWrite?: boolean;
};

export type UpdateProjectUserModel = {
  canWrite: boolean;
};

export type DbTablesNames = {
  tablesNames: string[];
};

export type CreateFlatFileModel = {
  tablesNames: string[];
};

export type FlatFileParametersModel = {
  extension: string;
  delimiter: string;
  dateTimeFormat: string;
  isHeaderIncluded: boolean;
};

export type CreateScriptDto = {
  levelId: string;
  name?: string | null;
  query?: string | null;
  position?: number;
};

export type UpdateScriptDto = {
  levelId: string;
  name?: string | null;
  query?: string | null;
  position: number;
};

export type ScriptVersionShortResponse = {
  id: string;
  version: number;
  createdDate: string;
  createdByUserName: string | null;
};

export type ScriptVersionResponse = ScriptVersionShortResponse & {
  name: string | null;
  query: string | null;
};

export enum LockStatus {
  Unlocked = 'Unlocked',
  ForceUnlocked = 'ForceUnlocked',
  Locked = 'Locked',
}

export type ScriptVersionChangeDto = {
  scriptVersionId: string;
  scriptVersionNumber: number;
  changedByUserId: string | null;
  changedByUserName: string | null;
  changedDate: string | null;
  status: LockStatus;
};

export type ScriptVersionChangeResponseWithCount = {
  items: ScriptVersionChangeDto[];
  totalCount: number;
};

export type ScriptLockedFields = {
  lockedByUserId?: string | null;
  lockedByUserName?: string | null;
  lockedDate?: string | null;
};

export type ScriptForLevelResponse = {
  id: string;
  name: string | null;
  query: string | null;
  position: number;
} & ScriptLockedFields;

export type ScriptInfoResponse = {
  projectName: string | null;
  templateName: string | null;
  version: number;
  createByUserName: string | null;
  lastUpdatedByUserName: string | null;
  createdDate: string;
  updatedDate: string | null;
} & ScriptForLevelResponse;

export type LevelResponse = {
  id: string;
  name: string | null;
  position: number;
  parentId: string | null;
  subLevels: LevelResponse[] | null;
  scripts: ScriptForLevelResponse[] | null;
  canEditTemplate: boolean;
};

export type CreateLevelDto = {
  templateId: string;
  name: string;
  position?: number;
  parentId: string | null;
};

export type UpdateLevelDto = {
  name: string | null;
  position: number;
  parentId?: string | null;
};

export type SqlUserResponse = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  canWrite: boolean;
  hasLogin: boolean;
  canChangeAccess: boolean;
};

export type SqlUserResponsePagedResponse = {
  totalCount: number;
  items: SqlUserResponse[];
};

export type SqlConfigurationResponse = {
  id: string;
  name: string | null;
};

export type AssignToProjectModel = {
  userId: string;
  canWrite: boolean;
};

export type LoginResponse = {
  sqlLogin: string | null;
  temporaryPassword: string | null;
};

export type ApplicationTypeDto = {
  id: string;
  name: string;
};

export type ApplicationDto = {
  id: string;
  name: string;
  applicationType: ApplicationTypeDto;
};

export type CreateApplicationRequest = {
  typeId: string;
  name: string;
};

export type UpdateApplicationRequest = {
  id: string;
  typeId: string;
  name: string;
};

export enum ProcessType {
  Restore = 'Restore',
  Backup = 'Backup',
  FlatFile = 'FlatFile',
  Comparison = 'Comparison',
  RestoreAndComparison = 'RestoreAndComparison',
}

export enum DatabaseStatus { // in swagger calls "Status"
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
  CompletedWithFailures = 'CompletedWithFailures',
  CompletedWithWarning = 'CompletedWithWarning',
}

export type DatabaseHistoryResponse = {
  id: string;
  processType: ProcessType;
  userName: string | null;
  selectedFile: string | null;
  startDate: string;
  endDate: string | null;
  status: DatabaseStatus;
  savedToSFTP: boolean;
  previousFile: string | null;
  runNumber: number | null;
  errorMessage: string | null;
  flatFileConfiguration: string | null;
  flatFileTables: string | null;
  databaseRestoreType: DatabaseRestoreType;
};

export type DatabaseHistoryResponsePagedResponse = {
  totalCount: number;
  items: DatabaseHistoryResponse[];
};

export type SearchDataHistoryTableSummaryResponse = {
  id: string;
  databaseHistoryId: string;
  name: string | null;
  columnsCount: number;
};

export type SearchDataHistoryTableSummaryResponsePagedResponse = {
  totalCount: number;
  items: SearchDataHistoryTableSummaryResponse[] | null;
};

export type GetDataHistoryColumnSummaryResponse = {
  id: string;
  name: string | null;
  dataType: string | null;
  rowCount: number;
  sum: string | null;
  min: string | null;
  max: string | null;
  maxLength: string | null;
  order: number;
};

export type GetDataHistoryTableSummaryDetailsResponse = {
  id: string;
  databaseHistoryId: string;
  name: string | null;
  columnsCount: number;
  columns: GetDataHistoryColumnSummaryResponse[];
};

export type DataComparisonSummary = {
  id: string;
  databaseHistoryId: string;
  tablesFailed: number;
  tablesWithWarnings: number;
  tablesPassed: number;
  tablesAnalysed: number;
};

export type UserPermissionResponse = {
  userId: string;
  companyId: string;
  companyName: string;
  schemeId: string;
  schemeName: string;
  productId: string;
  productName: string;
  productRoleId: string;
  productRoleName: GlobalRole;
  userProductRoleId: string;
  assignmentDate: string;
};

export enum MappingProjectStatus {
  Active = 'Active',
  SetupAddColumnsIncomplete = 'SetupAddColumnsIncomplete',
  SetupAssignTemplatesIncomplete = 'SetupAssignTemplatesIncomplete',
}

export enum MappingProjectType {
  SingleFile = 'SingleFile',
  MultipleFiles = 'MultipleFiles',
}

export type CreateMappingProjectDto = {
  name: string;
  mappingKey: string;
  type: MappingProjectType;
};

export type MappingProjectResponse = {
  id: string;
  name: string;
  mappingKey: string;
  status: MappingProjectStatus;
  type: MappingProjectType;
  createdDate: string;
  updatedDate: string | null;
};

export type MappingColumnResponse = {
  id: string;
  name: string | null;
  createDate: string | null;
};

export type MappingSourceTableResponse = {
  id: string;
  name: string | null;
  mappingColumns: MappingColumnResponse[];
};

export type CreateMappingColumnModel = {
  name: string;
  mappingSourceTableId: string;
};

export type CreateMappingSourceTableDto = {
  name: string;
};

export type UpdateMappingColumnModel = {
  name: string;
};

export type UpdateMappingTableDto = {
  name: string;
};

export type MappingProjectResponsePagedResponse = {
  totalCount: number;
  items: MappingProjectResponse[];
};

export type AssignMappingProjectToTemplateModel = {
  mappingTemplateId: string;
  mappingProjectId: string;
  newMappingTemplateName?: string;
};

export type MappingProjectTemplateModel = {
  mappingTemplateId: string;
  executionOrder: number;
};

export type CreateDataDictionaryRequest = {
  applicationId: string;
  releaseVersion: string;
};

export type FileUploadResponse = {
  id: string | null;
  status: string | null;
  errors: string[] | null;
};

export enum DataDictionaryStatus {
  Draft = 'Draft',
  Available = 'Available',
  Archived = 'Archived',
}

export type SearchDataDictionaryResponse = {
  id: string;
  applicationId: string;
  releaseVersion: string | null;
  status: DataDictionaryStatus;
  createdDate: string;
  updatedDate: string | null;
  applicationName: string | null;
  applicationType: string | null;
};

export type SearchDataDictionaryResponsePagedResponse = {
  totalCount: number;
  items: SearchDataDictionaryResponse[];
};

export type DataDictionaryTableResponse = {
  id: string;
  dataDictionaryId: string;
  name: string | null;
  required: boolean;
};

export type DataDictionaryTableResponsePagedResponse = {
  totalCount: number;
  items: DataDictionaryTableResponse[] | null;
};

export type DataDictionaryTableResponseExtendedPagedResponse = DataDictionaryTableResponsePagedResponse & {
  rawTotalCount: number;
};

export type DataDictionaryColumnResponse = {
  id: string;
  dataDictionaryTableId: string;
  name: string | null;
  order: number;
  required: boolean;
  dataType: string | null;
  parameters: string | null;
  columnLength: string;
};

export type DataDictionaryTableDetailsResponse = {
  id: string;
  dataDictionaryId: string;
  name: string | null;
  required: boolean;
  columns: DataDictionaryColumnResponse[] | null;
};

export type DataDictionaryTableColumnDto = {
  name?: string;
  required: boolean;
  order: number;
  dataType: string;
  parameters?: string;
  columnLength?: string;
};

export type UpdateDataDictionaryTableDto = {
  tableName: string;
  isTableRequired: boolean;
  dataDictionaryTableId: string;
  columns: DataDictionaryTableColumnDto[];
};

export type AddDataDictionaryTableDto = {
  tableName: string;
  isTableRequired: boolean;
  columns: DataDictionaryTableColumnDto[];
};

export type DataDictionaryResponse = {
  id: string;
  applicationId: string;
  applicationName: string | null;
  applicationTypeId: string;
  applicationTypeName: string | null;
  releaseVersion: string | null;
  adminDataSourceName: string | null;
  adminDataSourceId: string | null;
  status: DataDictionaryStatus;
  enhancedDataTypeEnabled: boolean;
};

export type UpdateDataDictionaryDto = {
  releaseVersion: string;
};

export type UpdateAuditDataDictionaryDto = {
  releaseVersion: string;
};

export enum ProjectUploadType {
  BackUp = 'BackUp',
  FlatFile = 'FlatFile',
}
export enum FlatFileUploadType {
  SingleFile = 'SingleFile',
  MultipleFiles = 'MultipleFiles',
}

export type UploadTypeDto = {
  type: ProjectUploadType;
  extension?: string | null;
  delimiter?: string | null;
  fileType?: FlatFileUploadType | null;
  textQualifier?: string | null;
  isHeaderIncluded?: boolean | null;
};

export type SearchAuditDataDictionaryResponse = {
  id: string;
  adminDataSourceId: string;
  releaseVersion: string | null;
  status: DataDictionaryStatus;
  createdDate: string;
  updatedDate: string | null;
  adminDataSourceName: string | null;
};

export type SearchAuditDataDictionaryResponsePagedResponse = {
  totalCount: number;
  items: SearchAuditDataDictionaryResponse[];
};

export type AdminDataSourceResponse = {
  id: string;
  name: string;
};

export type CreateAuditTemplateDto = {
  name: string;
  adminDataSourceId?: string;
  auditType?: AuditTemplateType;
  projectId?: string;
};

export type UpdateAuditTemplateDto = {
  name: string;
  adminDataSourceId?: string;
  projectId?: string;
  version: number;
  status: TemplateStatus;
};

export type CompanyAdminDataSourceResponse = {
  id: string;
  adminDataSourceId: string;
  name: string | null;
};

export enum ProjectDefault {
  Yes = 'Yes',
  No = 'No',
}

export type UpdateAuditProjectDto = {
  name: string;
  companyId: string;
  schemeIds: string[];
  projectDefault: ProjectDefault;
  isEmailsEnabled: boolean;
};

export type CreateAuditProjectDto = Omit<UpdateAuditProjectDto, 'isEmailsEnabled'> & {
  companyAdminDataSourceId: string;
};

export type SchemeAuditProjectResponse = {
  schemeId: string;
  projectId: string;
  scheme: SchemeResponse;
  project: unknown;
};

export type AuditProjectResponse = {
  id: string;
  name: string | null;
  sourceDatabaseName: string;
  destinationDatabaseName: string;
  currentBackupName: string;
  status: ProjectStatus;
  createdDate: string | null;
  isLocked: boolean;
  lockReason: LockReason;
  createdByUserId: string;
  companyAdminDataSourceId: string;
  companyAdminDataSource: CompanyAdminDataSourceResponse | null;
  schemes: SchemeAuditProjectResponse[] | null;
  projectDefault: ProjectDefault;
  defaultProjectExists: boolean;
  isEmailsEnabled: boolean;
};

export type CreateAuditDataDictionaryRequest = {
  adminDataSourceId: string;
  releaseVersion: string;
};

export type AuditDataDictionaryResponse = {
  id: string;
  adminDataSourceId: string;
  adminDataSourceName: string | null;
  releaseVersion: string | null;
  status: DataDictionaryStatus;
};

export type SearchAuditProjectResponse = {
  id: string;
  adminDataSource: string | null;
  name: string | null;
  createdDate: string;
  updatedDate: string | null;
  status: ProjectStatus;
};

export type SearchAuditProjectResponsePagedResponse = {
  totalCount: number;
  items: SearchAuditProjectResponse[];
};

export type AuditDatabaseResponse = {
  schemeName: string | null;
  sourceDatabaseName: string | null;
  destinationDatabaseName: string | null;
  lastUploadedFile: string | null;
};

export type AuditDatabaseResponsePagedResponse = {
  totalCount: number;
  items: AuditDatabaseResponse[] | null;
};

export type AuditProjectRestoreModel = {
  schemeId: string;
  s3Filename: string;
};

export type AuditProjectCompareModel = {
  schemeId: string;
};

export type AuditDatabaseHistoryResponse = {
  id: string;
  processType: ProcessType;
  userName: string | null;
  fileName: string | null;
  startDate: string;
  endDate: string | null;
  status: DatabaseStatus;
  previousFile: string | null;
  runNumber: number | null;
  errorMessage: string | null;
  sourceFileId: string;
  schemeName: string | null;
};

export type AuditDatabaseHistoryResponsePagedResponse = {
  totalCount: number;
  items: AuditDatabaseHistoryResponse[];
};

export type AuditProjectRunModel = {
  schemeId: string;
  templates: TemplateRunModel[];
};

export enum RunType {
  Full = 'Full',
  Test = 'Test',
}

export type AuditProjectRunResponse = {
  id: string;
  sourceFileId: string;
  runNumber: number;
  scheme: string | null;
  runDate: string;
  runByUserName: string | null;
  errorMessage: string | null;
  status: string | null;
  runType: string | null;
};

export type AuditProjectRunResponsePagedResponse = {
  totalCount: number;
  items: AuditProjectRunResponse[];
};

export type AuditProjectRunDetailResponse = {
  id: string;
  sourceFileId: string;
  runNumber: number;
  runDate: string;
  runByUserName: string;
  errorMessage: string | null;
  scheme: string | null;
  status: RunStatus;
  runType: RunType;
  templateRuns: TemplateRunResponse[];
};

export type AuditSchemeLockResponse = {
  id: string;
  name: string | null;
  isLocked: boolean;
  schemeLockReason: string | null;
};

export enum EmailNotifications {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}
