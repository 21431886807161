import { useMemo } from 'react';

import { useSetDynamicBreadcrumbOptions } from 'src/components/BreadcrumbsTrail';

import { ScriptInfoResponse, TemplateResponse } from 'src/types';

type UseSetDynamicScriptBreadcrumbsProps = Readonly<{
  template: TemplateResponse | undefined;
  script: ScriptInfoResponse | undefined;
  levelId: string;
}>;

export const TEMPLATE_NAME_BC = 'TEMPLATE_NAME';
export const SCRIPT_NAME_BC = 'SCRIPT_NAME';
export const MASTER_DATA_DICTIONARY_NAME_BC = 'MASTER_DATA_DICTIONARY_NAME';

export const useSetDynamicScriptBreadcrumbs = ({ template, script, levelId }: UseSetDynamicScriptBreadcrumbsProps) => {
  const breadcrumbsLocationState = useMemo(() => ({ expandedLevelId: levelId }), [levelId]);

  useSetDynamicBreadcrumbOptions({
    routePath: TEMPLATE_NAME_BC,
    name: template ? `${template.name || 'Template'}'s scripts` : null,
    locationState: breadcrumbsLocationState,
  });

  useSetDynamicBreadcrumbOptions({
    routePath: SCRIPT_NAME_BC,
    name: script ? script.name || 'Script' : null,
  });
};
