import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch as useDispatchOriginal, useSelector } from 'react-redux';

import { resetStore } from './slices/storeSlice';
import authReducer from './slices/authSlice';
import companyReducer from './slices/companySlice';
import configurationReducer from './slices/configurationSlice';
import mappingProjectReducer from './slices/mappingProjectSlice';
import productReducer from './slices/productSlice';
import projectReducer from './slices/projectSlice';
import projectPermissionsReducer from './slices/projectPermissionsSlice';
import routerReducer from './slices/routerSlice';
import scriptSlice from './slices/scriptSlice';
import tenantReducer from './slices/tenantSlice';
import breadcrumbsReducer from './slices/breadcrumbsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  company: companyReducer,
  configuration: configurationReducer,
  mappingProject: mappingProjectReducer,
  product: productReducer,
  project: projectReducer,
  projectPermissions: projectPermissionsReducer,
  router: routerReducer,
  script: scriptSlice,
  tenant: tenantReducer,
  breadcrumbs: breadcrumbsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof rootReducer>;
export type StoreDispatch = typeof store.dispatch;

export const useDispatch = () => useDispatchOriginal<StoreDispatch>();
export { useSelector, resetStore };

export default store;
