import { Suspense, useState, useLayoutEffect, lazy } from 'react';
import { Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RoutePath, history, GuardedRoute } from 'src/router';

import store, { useSelector } from 'src/store';
import { isLoggedInSelector } from 'src/store/selectors/authSelector';
import usePreviousLocationInStore from 'src/hooks/usePreviousLocationInStore';

import { PageLoading } from '@itm/shared-frontend/lib/components';

import Layout from 'src/components/layout/Layout';
import AuthProvider from 'src/hocs/AuthProvider';
import SyncFiltersWithURL from 'src/hocs/SyncFiltersWithURL';

import { Role } from 'src/types';
import { isAzureTargetPlatform } from 'src/utils/constants';

import LoginRedirectPage from 'src/pages/loginRedirect';
import PermissionDeniedPage from 'src/pages/permissionDenied';
import BreadcrumbsTrail from 'src/components/BreadcrumbsTrail';
//Additional Options
const ProductInfoRoutes = lazy(() => import('src/pages/additionalOptions/productInfo'));
const SQLCredentialsRoutes = lazy(() => import('src/pages/additionalOptions/sqlCredentials'));

// Migrations
const MigrationProjectsRoutes = lazy(() => import('src/pages/migrationProjects'));
const MasterDataDictionaryRoutes = lazy(() => import('src/pages/masterDataDictionary'));
const ApplicationRoutes = lazy(() => import('src/pages/application'));

// Translations
const MappingsRoutes = lazy(() => import('src/pages/mappings'));

// Master Templates
const MigrationMasterTemplatesRoutes = lazy(() => import('src/pages/masterTemplates/MigrationMasterTemplatesRoutes'));
const TranslationsMasterTemplatesRoutes = lazy(
  () => import('src/pages/masterTemplates/TranslationsMasterTemplatesRoutes'),
);
// Data Onboarding
const OnboardingRoutes = lazy(() => import('src/pages/onboarding'));

function RootRoutesFallback() {
  return (
    <div className="px-3">
      <div className="container">
        <PageLoading />
      </div>
    </div>
  );
}

function RootRoutes() {
  const isLoggedIn = useSelector(isLoggedInSelector);

  usePreviousLocationInStore();
  return (
    <Layout>
      <BreadcrumbsTrail />

      <Suspense fallback={<RootRoutesFallback />}>
        <Routes>
          <Route
            index
            element={
              <Navigate to={isLoggedIn ? RoutePath.migrationProjectsRoot : RoutePath.loginRedirect} replace={true} />
            }
          />
          <Route path={RoutePath.loginRedirect} element={<LoginRedirectPage />} />
          <Route path={RoutePath.permissionDenied} element={<PermissionDeniedPage />} />

          {/* Additional options */}
          <Route
            path={`${RoutePath.additionalOptionsRoot}/*`}
            element={
              <GuardedRoute
                meta={{
                  auth: true,
                  role: {
                    allowList: [Role.SuperAdmin, Role.Support],
                  },
                }}
              >
                <Routes>
                  <Route index element={<Navigate to="sql-credentials" replace={true} />} />

                  <Route path="sql-credentials/*" element={<SQLCredentialsRoutes />} />
                  <Route path="product-info/*" element={<ProductInfoRoutes />} />

                  <Route path="*" element={<Navigate to="./" />} />
                </Routes>
              </GuardedRoute>
            }
          />

          {/* Migrations */}
          <Route
            path={`${RoutePath.migrationRoot}/*`}
            element={
              <GuardedRoute
                meta={{
                  auth: true,
                  role: {
                    allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin],
                  },
                }}
              >
                <Routes>
                  <Route index element={<Navigate to="projects" replace={true} />} />

                  <Route path="projects/*" element={<MigrationProjectsRoutes />} />

                  <Route path="master-templates/*" element={<MigrationMasterTemplatesRoutes />} />

                  <Route path="data-dictionary/*" element={<MasterDataDictionaryRoutes />} />

                  <Route path="application/*" element={<ApplicationRoutes />} />

                  <Route path="*" element={<Navigate to="./" />} />
                </Routes>
              </GuardedRoute>
            }
          />

          {!isAzureTargetPlatform && (
            <>
              {/* Translations */}
              <Route
                path={`${RoutePath.translationsRoot}/*`}
                element={
                  <Routes>
                    <Route index element={<Navigate to="mappings" replace={true} />} />
                    <Route
                      path="mappings/*"
                      element={
                        <GuardedRoute
                          meta={{ auth: true, role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst] } }}
                        >
                          <MappingsRoutes />
                        </GuardedRoute>
                      }
                    />
                    <Route
                      path="master-templates/*"
                      element={
                        <GuardedRoute
                          meta={{ auth: true, role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst] } }}
                        >
                          <TranslationsMasterTemplatesRoutes />
                        </GuardedRoute>
                      }
                    />

                    <Route path="*" element={<Navigate to="mappings" />} />
                  </Routes>
                }
              />

              {/* Onboarding */}
              <Route
                path={`${RoutePath.onboardingRoot}/*`}
                element={
                  <GuardedRoute
                    meta={{ auth: true, role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst] } }}
                  >
                    <OnboardingRoutes />
                  </GuardedRoute>
                }
              />
            </>
          )}

          <Route path="*" element={<Navigate to={RoutePath.root} />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

function RootRouter() {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setState), []);
  return (
    <Router location={state.location} navigationType={state.action} navigator={history}>
      <Provider store={store}>
        <AuthProvider>
          <SyncFiltersWithURL>
            <RootRoutes />
          </SyncFiltersWithURL>
        </AuthProvider>
      </Provider>
    </Router>
  );
}

export default RootRouter;
