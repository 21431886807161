import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { MappingProjectResponse } from 'src/types';

type MappingProjectState = {
  mappingProject: MappingProjectResponse | null;
};

const initialState: MappingProjectState = {
  mappingProject: null,
};

const mappingProjectSlice = createSlice({
  name: 'mappingProject',
  initialState,
  reducers: {
    setMappingProject(state: MappingProjectState, action: PayloadAction<MappingProjectResponse>) {
      state.mappingProject = action.payload;
    },
    resetMappingProject(state: MappingProjectState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, (state: MappingProjectState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setMappingProject, resetMappingProject } = mappingProjectSlice.actions;

export default mappingProjectSlice.reducer;
