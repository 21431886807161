import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

import { serializeError } from 'src/utils/redux';
import { getProjectById } from 'src/api/dataTransformation/project';

import { ProjectDetailsResponse, ServerFormErrors } from 'src/types';

type ProjectState = {
  isLoading: boolean;
  project: ProjectDetailsResponse | null;
  serverErrors: ServerFormErrors;
};

export const fetchProjectByIdAction = createAsyncThunk(
  'project/fetchProjectByIdAction',
  async (projectId: string) => {
    const res = await getProjectById(projectId);
    return res.data;
  },
  {
    serializeError,
  },
);

const initialState: ProjectState = {
  isLoading: true,
  project: null,
  serverErrors: [],
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setIsLoadingProject(state: ProjectState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    resetProject(state: ProjectState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectByIdAction.pending, (state: ProjectState) => {
      state.isLoading = true;
      state.serverErrors = [];
    });
    builder.addCase(fetchProjectByIdAction.fulfilled, (state: ProjectState, action) => {
      state.project = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchProjectByIdAction.rejected, (state: ProjectState, action) => {
      state.serverErrors = action.error.message ? [action.error.message] : [];
      state.isLoading = false;
    });

    // Cleanup
    builder.addCase(resetStore, (state: ProjectState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { resetProject, setIsLoadingProject } = projectSlice.actions;

export default projectSlice.reducer;
