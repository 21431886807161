import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { getProductInfo } from 'src/api/dataTransformation/product';
import { ProductInfoResponse } from 'src/types';

type ProductState = {
  productInfo: Partial<ProductInfoResponse>;
};

export const fetchProductInfoAction = createAsyncThunk('product/fetchProductDescription', async () => {
  const res = await getProductInfo();
  return res.data;
});

const initialState: ProductState = {
  productInfo: {},
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductDescription(state: ProductState, action: PayloadAction<Pick<ProductInfoResponse, 'description'>>) {
      state.productInfo = { ...state.productInfo, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductInfoAction.fulfilled, (state: ProductState, action) => {
      state.productInfo = action.payload;
    });

    // Cleanup
    builder.addCase(resetStore, (state: ProductState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setProductDescription } = productSlice.actions;

export default productSlice.reducer;
