import classNames from 'classnames';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import {
  isLoggedInSelector,
  authUserIdSelector,
  authRoleSelector,
  authRoleListSelector,
} from 'src/store/selectors/authSelector';
import {
  tenantBrandingLogoUrlSelector,
  tenantBrandingLogoAltSelector,
  tenantBrandingNameSelector,
} from 'src/store/selectors/tenantBrandingSelector';
import { selectedCompanyIdSelector } from 'src/store/selectors/companySelector';
import { selectedProjectSelector, isProjectDBCreationSelector } from 'src/store/selectors/projectSelector';
import { selectedMappingProjectSelector } from 'src/store/selectors/mappingProjectSelector';
import { selectedConfigurationSelector } from 'src/store/selectors/configurationSelector';

import { Navigation } from '@itm/shared-frontend/lib/components';
import { clientPortalDashboardUrl } from '@itm/shared-frontend/lib/utils';
import {
  MenuIcon,
  MigrationIcon,
  AuditIcon,
  DataTranslationsIcon,
  HideIcon,
  SettingIcon,
} from '@itm/shared-frontend/lib/components/icons';
import type { NavigationLocalConfig } from '@itm/shared-frontend/lib/components/Navigation';

import Filters from 'src/components/Filters';

import { isAzureTargetPlatform } from 'src/utils/constants';

import { getPermissionsAssignedToUser } from 'src/api/clientPortal/permissions';

import { Role, GlobalRole, UserPermissionResponse } from 'src/types';

import styles from './Sidebar.module.scss';

type Props = Readonly<{
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}>;

function Sidebar({ isActive, setIsActive }: Props) {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userId = useSelector(authUserIdSelector);
  const userRole = useSelector(authRoleSelector);
  const userRoleListFromToken = useSelector(authRoleListSelector);

  const brandingLogoUrl = useSelector(tenantBrandingLogoUrlSelector);
  const brandingLogoAlt = useSelector(tenantBrandingLogoAltSelector);
  const brandingName = useSelector(tenantBrandingNameSelector);

  const project = useSelector(selectedProjectSelector);
  const isProjectDBCreation = useSelector(isProjectDBCreationSelector);

  const mappingProject = useSelector(selectedMappingProjectSelector);
  const configuration = useSelector(selectedConfigurationSelector);

  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const [userPermissionList, setUserPermissionList] = useState<UserPermissionResponse[]>([]);

  const userGlobalRoleListByCompany = useMemo(
    () =>
      selectedCompanyId && userPermissionList.length
        ? userPermissionList.reduce<GlobalRole[]>((acc, { companyId, productRoleName }) => {
            if (selectedCompanyId === companyId && !acc.includes(productRoleName)) {
              acc.push(productRoleName);
            }
            return acc;
          }, [])
        : [],
    [selectedCompanyId, userPermissionList],
  );

  const userRoleList = useMemo<GlobalRole[]>(() => {
    if (!userRole) return [];
    if (userRole === Role.SuperAdmin || userRole === Role.Support) return [userRole] as unknown as GlobalRole[];
    const clientPortalRole = userRoleListFromToken.find((role) => role.startsWith('ClientPortal'));

    return clientPortalRole ? [clientPortalRole, ...userGlobalRoleListByCompany] : userGlobalRoleListByCompany;
  }, [userGlobalRoleListByCompany, userRole, userRoleListFromToken]);

  const fetchUserPermissionList = useCallback(async () => {
    if (!userId || userRole === Role.SuperAdmin || userRole === Role.Support) return;
    try {
      const res = await getPermissionsAssignedToUser(userId);
      setUserPermissionList(res.data);
    } catch (e) {
      console.warn('User Permissions unavailable');
    }
  }, [userId, userRole]);

  const navigationLocalConfig = useMemo<NavigationLocalConfig<Role | GlobalRole[]>>(() => {
    const projectId = project?.id || '';
    const mappingProjectId = mappingProject?.id || '';
    const configurationId = configuration?.id || '';

    return [
      {
        name: 'All Products',
        section: 'products',
        path: clientPortalDashboardUrl,
        icon: () => <MenuIcon />,
      },
      {
        name: 'Additional Options',
        section: 'products',
        path: RoutePath.additionalOptionsRoot,
        isInternalRoute: true,
        icon: () => <SettingIcon />,
        role: { allowList: [Role.SuperAdmin, Role.Support] },
        children: [
          {
            name: 'SQL server credentials',
            path: RoutePath.additionalOptionsSqlCredentialsRoot,
            children: [
              {
                name: 'User SQL credentials',
                path: RoutePath.additionalOptionsSqlCredentialsUsers,
              },
            ],
          },
          {
            name: 'Product information',
            path: RoutePath.additionalOptionsProductInfoRoot,
            children: [
              {
                name: 'Technical details',
                path: RoutePath.additionalOptionsProductInfoDetails,
              },
            ],
          },
        ],
      },

      {
        name: 'Migrations',
        section: 'products',
        path: RoutePath.migrationRoot,
        isInternalRoute: true,
        icon: () => <MigrationIcon />,
        role: {
          allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin],
        },
        children: [
          {
            name: 'Projects',
            path: RoutePath.migrationProjectsRoot,
            children: [
              {
                name: 'Create new project',
                path: RoutePath.migrationProjectsCreateRoot,
              },
              {
                name: 'All projects',
                path: RoutePath.migrationProjectsList,
              },
              {
                name: isProjectDBCreation ? null : project?.name,
                path: generatePath(RoutePath.migrationProjectRoot, { projectId: project?.id || '' }),
                children: [
                  {
                    name: 'Project Details',
                    path: generatePath(RoutePath.migrationProjectDetails, { projectId }),
                  },
                  {
                    name: 'Data Dictionary',
                    path: generatePath(RoutePath.migrationProjectDataDictionaryRoot, { projectId }),
                  },
                  {
                    name: 'Project Databases',
                    path: generatePath(RoutePath.migrationProjectDatabasesRoot, { projectId }),
                  },
                  {
                    name: 'Project Templates',
                    path: generatePath(RoutePath.migrationProjectTemplatesRoot, { projectId }),
                  },
                  {
                    name: 'Project User Permissions',
                    path: generatePath(RoutePath.migrationProjectUsersRoot, { projectId }),
                  },
                ],
              },
            ],
          },
          {
            name: 'Master Templates',
            path: RoutePath.masterTemplatesRoot,
            children: [
              {
                name: 'Create new template',
                path: RoutePath.masterTemplatesCreate,
              },
              {
                name: 'All templates',
                path: RoutePath.masterTemplatesList,
              },
            ],
          },
          {
            name: 'Master data dictionary',
            path: RoutePath.masterDataDictionaryRoot,
            children: [
              {
                name: 'Create new dictionary',
                path: RoutePath.masterDataDictionaryCreateRoot,
              },
              {
                name: 'All master dictionaries',
                path: RoutePath.masterDataDictionaryList,
              },
            ],
          },
          {
            name: 'Applications',
            path: RoutePath.applicationRoot,
            children: [
              {
                name: 'Create new application',
                path: RoutePath.applicationCreate,
              },
              {
                name: 'All applications',
                path: RoutePath.applicationList,
              },
            ],
          },
        ],
      },
      {
        name: 'Fusion PSI Configuration',
        section: 'products',
        path: RoutePath.translationsRoot,
        isInternalRoute: true,
        icon: () => <DataTranslationsIcon />,
        role: {
          allowList: isAzureTargetPlatform
            ? []
            : [Role.SuperAdmin, Role.Support, [GlobalRole.DataTransformation_Analyst, GlobalRole.DashboardISP_Analyst]],
        },
        children: [
          {
            name: 'Mappings',
            path: RoutePath.translationsMappingsRoot,
            children: [
              {
                name: 'Create new mapping',
                path: RoutePath.translationsMappingsCreateRoot,
              },
              {
                name: 'All Mappings',
                path: RoutePath.translationsMappingsList,
              },
              {
                name: mappingProject?.name,
                path: generatePath(RoutePath.translationsMappingProjectRoot, { mappingProjectId }),
                children: [
                  {
                    name: 'File Structure',
                    path: generatePath(RoutePath.translationsMappingProjectFileStructure, { mappingProjectId }),
                  },
                  {
                    name: 'Mapping Details',
                    path: generatePath(RoutePath.translationsMappingProjectDetails, { mappingProjectId }),
                  },
                  {
                    name: 'Mapping Templates',
                    path: generatePath(RoutePath.translationsMappingProjectTemplatesRoot, { mappingProjectId }),
                  },
                ],
              },
            ],
          },
          {
            name: 'Master Templates',
            path: RoutePath.translationsMasterTemplatesRoot,
            children: [
              {
                name: 'Create new template',
                path: RoutePath.translationsMasterTemplatesCreate,
              },
              {
                name: 'All templates',
                path: RoutePath.translationsMasterTemplatesList,
              },
            ],
          },
        ],
      },
      {
        name: 'Data Onboarding',
        section: 'products',
        path: RoutePath.onboardingRoot,
        isInternalRoute: true,
        icon: () => <AuditIcon />,
        role: {
          allowList: isAzureTargetPlatform
            ? []
            : [Role.SuperAdmin, Role.Support, [GlobalRole.DataTransformation_Analyst, GlobalRole.DataAudit_Analyst]],
        },
        children: [
          {
            name: 'Configurations',
            path: RoutePath.onboardingConfigurationsRoot,
            children: [
              {
                name: 'All Configurations',
                path: RoutePath.onboardingConfigurationsList,
              },
              {
                name: 'Create New Configuration',
                path: RoutePath.onboardingConfigurationCreateRoot,
              },
              {
                name: configuration?.name,
                path: generatePath(RoutePath.onboardingConfigurationRoot, { configurationId }),
                children: [
                  {
                    name: 'Client Details',
                    path: generatePath(RoutePath.onboardingConfigurationDetails, { configurationId }),
                  },

                  {
                    name: 'Data dictionary',
                    path: generatePath(RoutePath.onboardingConfigurationDataDictionaryRoot, { configurationId }),
                  },
                  {
                    name: 'Databases',
                    path: generatePath(RoutePath.onboardingConfigurationDatabasesRoot, { configurationId }),
                  },
                  {
                    name: 'Mapping Templates',
                    path: generatePath(RoutePath.onboardingConfigurationTemplatesRoot, { configurationId }),
                  },
                  {
                    name: 'User Permissions',
                    path: generatePath(RoutePath.onboardingConfigurationUsersRoot, { configurationId }),
                  },
                ],
              },
            ],
          },
          {
            name: 'Master Templates',
            path: RoutePath.onboardingMasterTemplatesRoot,
            children: [
              {
                name: 'All Templates',
                path: RoutePath.onboardingMasterTemplatesList,
              },
              {
                name: 'Create New Template',
                path: RoutePath.onboardingMasterTemplatesCreate,
              },
            ],
          },
          {
            name: 'Master Data Dictionary',
            path: RoutePath.onboardingMasterDataDictionaryRoot,
            children: [
              {
                name: 'All Master Dictionaries',
                path: RoutePath.onboardingMasterDataDictionaryList,
              },
              {
                name: 'Create New Master Dictionary',
                path: RoutePath.onboardingMasterDataDictionaryCreateRoot,
              },
            ],
          },
        ],
      },
    ];
  }, [project, mappingProject, configuration, isProjectDBCreation]);

  useEffect(() => {
    fetchUserPermissionList();
  }, [fetchUserPermissionList]);

  return (
    <aside
      className={classNames([
        styles.Wrapper,
        'is-flex is-flex-direction-column has-background-white is-relative',
        {
          [styles.IsActive]: isActive,
        },
      ])}
    >
      <header className={classNames(styles.Header, 'is-flex-shrink-0')}>
        <div className="columns is-align-items-center is-gapless mb-0">
          <div className="column is-narrow">
            <Link className={classNames(styles.Logo, 'has-icon')} to={RoutePath.root} tabIndex={1}>
              <img src={brandingLogoUrl} alt={brandingLogoAlt} />
            </Link>
          </div>
          {isActive && (
            <div className="column">
              <h2 className="title is-nowrap is-4 ml-4">{brandingName}</h2>
            </div>
          )}
        </div>
        {isActive && <Filters />}
      </header>
      {isLoggedIn && (
        <>
          <button
            className={classNames(styles.ToggleButton, 'has-icon', { 'is-active': isActive })}
            type="button"
            onClick={() => setIsActive(!isActive)}
          >
            <span className="icon">
              <HideIcon />
            </span>
          </button>
          {userRole && (
            <Navigation
              isActiveSidebar={isActive}
              localConfig={navigationLocalConfig}
              userRoleList={userRoleList}
              isAzureTargetPlatform={isAzureTargetPlatform}
              isCommonItemsCollapse
            />
          )}
        </>
      )}
    </aside>
  );
}

export default Sidebar;
