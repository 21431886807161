import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

type ScriptState = {
  isUnlockScriptLoading: boolean;
};

const initialState: ScriptState = {
  isUnlockScriptLoading: false,
};

const scriptSlice = createSlice({
  name: 'script',
  initialState,
  reducers: {
    setIsUnlockScriptLoading(state: ScriptState, action: PayloadAction<boolean>) {
      state.isUnlockScriptLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, (state: ScriptState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setIsUnlockScriptLoading } = scriptSlice.actions;

export default scriptSlice.reducer;
