import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

import { SelectOption } from 'src/types';

export const configurationSliceSelector = (state: RootState) => state.configuration;

export const selectedConfigurationSelector = createSelector(configurationSliceSelector, (state) => state.configuration);

export const configurationAssignedSchemeListSelector = createSelector(selectedConfigurationSelector, (configuration) =>
  configuration?.schemes
    ? configuration.schemes
        .map(({ scheme }) => scheme)
        .sort((a, b) => {
          const aLabel = a.name || a.id;
          const bLabel = b.name || b.id;
          return aLabel.localeCompare(bLabel);
        })
    : [],
);

export const configurationAssignedSchemeSelectOptionsSelector = createSelector(
  configurationAssignedSchemeListSelector,
  (schemeList) => schemeList.map<SelectOption>(({ id, name }) => ({ label: name || id, value: id })),
);

export const configurationServerErrorsSelector = createSelector(
  configurationSliceSelector,
  (state) => state.serverErrors,
);
