import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

import { serializeError } from 'src/utils/redux';
import { getProjectAccess } from 'src/api/dataTransformation/project';

import { ProjectAccessPermissionResponse, ServerFormErrors } from 'src/types';

type ProjectPermissionsState = {
  serverErrors: ServerFormErrors;
  userPermissions: ProjectAccessPermissionResponse | null;
};

export const fetchUserPermissionsToProjectAction = createAsyncThunk(
  'projectPermission/fetchUserPermissionsToProjectAction',
  async (projectId: string) => {
    const res = await getProjectAccess(projectId);
    return res.data;
  },
  {
    serializeError,
  },
);

const initialState: ProjectPermissionsState = {
  userPermissions: null,
  serverErrors: [],
};

const projectPermissionSlice = createSlice({
  name: 'projectPermission',
  initialState,
  reducers: {
    resetProjectPermissions(state: ProjectPermissionsState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserPermissionsToProjectAction.pending, (state: ProjectPermissionsState) => {
      state.serverErrors = [];
    });
    builder.addCase(fetchUserPermissionsToProjectAction.fulfilled, (state: ProjectPermissionsState, action) => {
      state.userPermissions = action.payload;
    });
    builder.addCase(fetchUserPermissionsToProjectAction.rejected, (state: ProjectPermissionsState, action) => {
      state.serverErrors = action.error.message ? [action.error.message] : [];
    });

    // Cleanup
    builder.addCase(resetStore, (state: ProjectPermissionsState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { resetProjectPermissions } = projectPermissionSlice.actions;

export default projectPermissionSlice.reducer;
