import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from './storeSlice';

type BreadcrumbsStateItem = { [pathname: string]: string | null };
type BreadcrumbsStateLocationState = { [pathname: string]: Record<string, any> | null };

type BreadcrumbsState = {
  namesMap: BreadcrumbsStateItem;
  parentPathMap: BreadcrumbsStateItem;
  searchParamsMap: BreadcrumbsStateItem;
  locationStateMap: BreadcrumbsStateLocationState;
};

const initialState: BreadcrumbsState = {
  namesMap: {},
  parentPathMap: {},
  searchParamsMap: {},
  locationStateMap: {},
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumbsNames(state: BreadcrumbsState, action: PayloadAction<BreadcrumbsStateItem>) {
      state.namesMap = { ...state.namesMap, ...action.payload };
    },
    setBreadcrumbsParentPath(state: BreadcrumbsState, action: PayloadAction<BreadcrumbsStateItem>) {
      state.parentPathMap = { ...state.parentPathMap, ...action.payload };
    },
    setBreadcrumbsSearchParams(state: BreadcrumbsState, action: PayloadAction<BreadcrumbsStateItem>) {
      state.searchParamsMap = { ...state.searchParamsMap, ...action.payload };
    },
    setBreadcrumbsLocationState(state: BreadcrumbsState, action: PayloadAction<BreadcrumbsStateLocationState>) {
      state.locationStateMap = { ...state.locationStateMap, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, (state: BreadcrumbsState) => {
      Object.assign(state, initialState);
    });
  },
});

export const {
  setBreadcrumbsNames,
  setBreadcrumbsParentPath,
  setBreadcrumbsSearchParams,
  setBreadcrumbsLocationState,
} = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
