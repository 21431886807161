import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { LockReason, ProjectStatus } from 'src/types';

export const projectSliceSelector = (state: RootState) => state.project;

export const selectedProjectSelector = createSelector(projectSliceSelector, (state) => state.project);
export const projectNameSelector = createSelector(selectedProjectSelector, (project) => project?.name || '');
export const isLockedProjectSelector = createSelector(selectedProjectSelector, (project) =>
  project ? project.isLocked : false,
);
export const projectCurrentBackupNameSelector = createSelector(selectedProjectSelector, (project) =>
  project ? project.currentBackupName : null,
);

export const isLoadingProjectSelector = createSelector(projectSliceSelector, (state) => state.isLoading);

export const projectServerErrorsSelector = createSelector(projectSliceSelector, (state) => state.serverErrors);

export const isProjectDBCreationSelector = createSelector(selectedProjectSelector, (project) =>
  Boolean(
    project &&
      ((project.isLocked &&
        project.lockReason === LockReason.DatabaseCreation &&
        project.status === ProjectStatus.DatabaseCreation) ||
        project.status === ProjectStatus.DatabaseCreationFailed),
  ),
);
