import { useMemo, useRef, PropsWithChildren } from 'react';
import { generatePath, Navigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { isLoggedInSelector, authRoleSelector } from 'src/store/selectors/authSelector';
import { selectedProjectSelector } from 'src/store/selectors/projectSelector';
import { selectedConfigurationSelector } from 'src/store/selectors/configurationSelector';
import { userPermissionsSelector } from 'src/store/selectors/projectPermissionsSelector';

import { Role } from 'src/types';

type Props = Readonly<{
  meta: {
    auth?: boolean;
    lockedProject?: boolean;
    editProjectPermission?: boolean;
    role?: {
      allowList: Role[];
    };
  };
}>;

export function GuardedRoute({ meta, children }: PropsWithChildren<Props>) {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userRole = useSelector(authRoleSelector);
  const migrationProject = useSelector(selectedProjectSelector);
  const configuration = useSelector(selectedConfigurationSelector);
  const projectPermissions = useSelector(userPermissionsSelector);
  const hrefRef = useRef('');

  let redirectRoute = RoutePath.root;
  const projectBgProcessRoute = migrationProject
    ? generatePath(RoutePath.migrationProjectBackgroundProcess, {
        projectId: migrationProject.id,
      })
    : generatePath(RoutePath.onboardingConfigurationBackgroundProcess, {
        configurationId: configuration?.id || '',
      });

  const passAuthGuard = useMemo(() => {
    if (!meta.auth) return true;
    return isLoggedIn;
  }, [meta, isLoggedIn]);

  const passRolesGuard = useMemo(() => {
    if (!meta.role) return true;
    return userRole && meta.role.allowList.includes(userRole);
  }, [meta, userRole]);

  const passLockedProjectGuard = useMemo(() => {
    if (!meta.lockedProject || (!migrationProject && !configuration)) return true;
    return !migrationProject?.isLocked && !configuration?.isLocked;
  }, [meta, configuration, migrationProject]);

  const passEditProjectPermissionGuard = useMemo(() => {
    if (!meta.editProjectPermission || !projectPermissions) return true;
    return projectPermissions.canWrite;
  }, [meta, projectPermissions]);

  const isRouteAllowed = useMemo(
    () => passAuthGuard && passRolesGuard && passLockedProjectGuard && passEditProjectPermissionGuard,
    [passAuthGuard, passRolesGuard, passLockedProjectGuard, passEditProjectPermissionGuard],
  );

  if (passAuthGuard) {
    if (!passRolesGuard) {
      redirectRoute = RoutePath.permissionDenied;
    } else if (!passLockedProjectGuard) {
      redirectRoute = projectBgProcessRoute;
    } else if (!passEditProjectPermissionGuard) {
      redirectRoute = '../';
    }
  } else {
    redirectRoute = RoutePath.loginRedirect;
  }

  // Save full URL before logout
  if (isLoggedIn) {
    hrefRef.current = '';
  } else if (!hrefRef.current) {
    hrefRef.current = window.location.href;
  }

  return (
    <>{isRouteAllowed ? children : <Navigate to={redirectRoute} state={{ href: hrefRef.current }} replace={true} />}</>
  );
}
